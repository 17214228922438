<script>
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiFileExcel } from "@mdi/js";
    import { snackbarError } from "../../../stores/AppStatus";
    import QsDownloadTemplateDialog from "./QSDownloadTemplateDialog.svelte";
    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";

    export let disabled = false;
    export let authenticatedUser;
    export let supplierList = [];
    export let rowCount;

    let authUserSuppList = [];
    let supplierId;
    let supplierListBox = [];
    let supplierCount;
    let showSupplierSelectDialog = false;
    let showSupplierList = false;
    let showFillProductListCheckbox = false;
    let fillProductList = false;

    const dispatch = createEventDispatcher();

    $: {
        console.log({authenticatedUser});
        console.log({supplierList});
        console.log({rowCount});
    }

    $:  { if (authenticatedUser.isSupplier)
            if (Array.isArray(authenticatedUser.supplierList) && authenticatedUser.supplierList.length > 0) 
                authUserSuppList = [...new Set([...authenticatedUser.supplierList].map(x => {return {id: x.supplierId, name: x.supplierId + ' - ' + x.supplierName}})
                                    .filter((obj1, i, arr) => arr.findIndex(obj2 => JSON.stringify(obj2) === JSON.stringify(obj1)) === i))];
        console.log({authUserSuppList});
    }

    function buttonClicked(){
        console.log("================= buttonClicked ==============", {authenticatedUser}, {supplierList}, {rowCount});
        supplierCount = (Array.isArray(supplierList) && supplierList.length > 0)? supplierList.length: 0;
        //console.log({rowCount});
        //console.log({supplierCount});
        //console.log({supplierList});
        //console.log({authUserSuppList});
        if (authenticatedUser.isSupplier) {
            console.log("User is supplier: ", authUserSuppList);
            if (rowCount == 0) {
                // Empty table
                if (Array.isArray(authUserSuppList)) {
                    if (authUserSuppList.length == 0) {
                        // Error - User is not linked to any supplier
                        $snackbarError.text = $_("cannot_download_template_because_you_are_not_linked_to_any_supplier");
                        $snackbarError.element && $snackbarError.element.open();
                    } else if (authUserSuppList.length == 1) {
                        // User is linked to one supplier => download (empty / product list) template for week and the one supplier
                        supplierId = authUserSuppList[0].supplierId;
                        showSupplierList = false;
                        supplierListBox = [];
                        showFillProductListCheckbox = true;
                        fillProductList = false;
                        showSupplierSelectDialog = true;
                   } else {
                        // User is linked to multiple suppliers => select supplier from list and then download (empty / product list) template for week and selected supplier
                        showSupplierList = true;
                        supplierListBox = [...authUserSuppList];
                        supplierId = supplierListBox.at(0).id;
                        showFillProductListCheckbox = true;
                        fillProductList = false;
                        showSupplierSelectDialog = true;
                    }
                } else {
                    // Error - User is not linked to any supplier
                    $snackbarError.text = $_("cannot_download_template_because_you_are_not_linked_to_any_supplier");
                    $snackbarError.element && $snackbarError.element.open();
                }
            } else {
                // Table contains some data
                if (supplierCount == 1) {
                    // Table contains data from one suuplier => download template containing table data for week and the one supplier
                    dispatch("downloadQuoteSheetTemplate", {supplierId: supplierList[0].id, importProductList: false});
                } else {
                    // Table contains data from multiple suppliers => select supplier from list and then download template containing table data for week and selected supplier
                    showSupplierList = true;
                    let usersupplierListBox = [...authUserSuppList];
                    // Make intersection of users supplier list and datatable supplier list
                    supplierListBox = [...usersupplierListBox].filter(function(n1) {return (supplierList.some(function(n2){return n1.id == n2.id;}))});
                    showFillProductListCheckbox = false;
                    fillProductList = false;
                    supplierId = supplierListBox.at(0).id;
                    showSupplierSelectDialog = true;
                }
            }
        } else {
            console.log("User is buyer / admin");
            if (rowCount == 0) {
                // Empty table => download empty template with week number
                dispatch("downloadQuoteSheetTemplate", {supplierId: null, importProductList: false});
            } else {
                // Table contains some data
                if (supplierCount == 1) {
                    // One supplier => download template containing table data for week and supplier
                    dispatch("downloadQuoteSheetTemplate", {supplierId: supplierList[0].id, importProductList: false});
                } else {
                    // Multiple suppliers => select supplier from list and then download template containing table data for week and selected supplier
                    showSupplierList = true;
                    supplierListBox = [...supplierList];
                    showFillProductListCheckbox = false;
                    fillProductList = false;
                    supplierId = supplierListBox.at(0).id;
                    showSupplierSelectDialog = true;
                }
            }
        }
    }


</script>

<Button class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
    title={$_("download_excel_file")} on:click={buttonClicked} disabled={disabled}>
    <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
        <path fill="currentColor" d={mdiFileExcel} />
    </Icon>
    {$_("download_template")}
</Button>


<ConfirmationDialog
    open={showSupplierSelectDialog}
    title={$_("download_quote_sheet_template")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("download")}
    big={true}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showSupplierSelectDialog = false;
    }}
    on:confirm={() => {
        showSupplierSelectDialog = false;
        dispatch("downloadQuoteSheetTemplate", {supplierId: supplierId, importProductList: fillProductList});
    }}
>

    <QsDownloadTemplateDialog 
        supplierList={supplierListBox}
        {showSupplierList}
        {showFillProductListCheckbox}
        bind:supplierId
        bind:fillProductList
    />

</ConfirmationDialog>


<style>
</style>
