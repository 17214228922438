import Api from "../services/Api";

// Method to get current List of Suppliers
export const getSupplier = async (id, supplierName, status, rms_supplier_no) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (supplierName) params.append('name', supplierName);
    if (status !== "" && status != null && ((status == 0) || (status == 1))) params.append('status', status);
    if (rms_supplier_no) params.append('rms_supplier_no', rms_supplier_no); 

    console.log('getSupplier params:', params.toString());

    const response = await Api.get("/supplier/", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to get current List of Supplier's Products
export const getSupplierProduct = async (supplierId, productId, status) => {
  try {
    const params = new URLSearchParams();
    if (supplierId) params.append('supplierId', supplierId);
    if (productId) params.append('productId', productId);
    if (status !== "" && status != null && ((status == 0) || (status == 1))) params.append('status', status);

    console.log('getSupplierProduct params:', params.toString());

    const response = await Api.get("/supplier/product", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to get current List of RMS Suppliers
export const getSupplierLink = async (id, supplierId, country,rmsSupplierNo, rmsSupplierName, status) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (supplierId) params.append('supplierId', supplierId);
    if (country) params.append('country', country);
    if (rmsSupplierNo) params.append('rmsSupplierNo', rmsSupplierNo);
    if (rmsSupplierName) params.append('rmsSupplierName', rmsSupplierName);
    if (status !== "" && status != null && ((status == 0) || (status == 1))) params.append('status', status);

    console.log('getSupplierLink params:', params.toString());

    const response = await Api.get("/supplier/link", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log("Get RMS Supp Link API:", error.response.data);
    if (error.response.data.message === "Not found" && Array.isArray(error.response.data.payload) && error.response.data.payload[0] === null) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error.message, { cause: error });
    }
  }
};

//  Method to get dynamic response
export const getListData = async (list, id) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);

    console.log('getListData', list,' params:', params.toString());

    const response = await Api.get("/" + list, params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log("Get API:", error.response.data);
    if (error.response.data.message === "Not found" && Array.isArray(error.response.data.payload) && error.response.data.payload[0] === null) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error.message, { cause: error });
    }
  }
};

// Method to get contact, linked to spec. ce_supplier/rms_link
export const getContact = async (list, supplierId, supplierLinkId) => {
  try {
    const params = new URLSearchParams();
    if (supplierId) params.append('supplierId', supplierId);
    if (supplierLinkId) params.append('supplierLinkId', supplierLinkId);

    console.log('getContact', list, ' params:', params.toString());

    const response = await Api.get("/" + list, params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log("Contact API:", error.response.data);
    if (error.response.data.message === "Not found" && Array.isArray(error.response.data.payload) && error.response.data.payload[0] === null) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error.message, { cause: error });
    }
  }
};

// Method to create new row
export const createRow = async (type, data) => {
  console.log('createRow:', type, { data });
  try {
    const response = await Api.post("/" + type, data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to create update row
export const updateRow = async (type, data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    const response = await Api.put("/" + type, params, data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to create delete row
export const deleteRow = async (type, id) => {
  try {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await Api.delete("/" + type, params);
    console.log({ response });

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    console.error(error.response.data.payload[0].includes('ORA-02292'));
    if (error.response.data.payload[0].includes('ORA-02292')) { console.error('CHLD RECORDS FOUND'); }
    throw new Error(error.message, { cause: error });
  }
};

// Method to get current List of Suppliers
export const getUserSuppliers = async (uuid) => {
  try {
    const params = new URLSearchParams();
    params.append('uuid', uuid);
    const response = await Api.get("/supplier/user/suppliers", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};
