<script>
    import IconButton, { Icon } from "@smui/icon-button";
    import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

    import SelectorProduct from "./SelectorProduct.svelte";

    import Flex from "svelte-flex";

    import { _ } from "svelte-i18n";

    export let selectedProduct;
    export let products = [];

    const prevProduct = () => {
        console.log({ selectedProduct });
        if (selectedProduct.navigationId > 1) {
            selectedProduct = products[selectedProduct.navigationId - 2];
        }
    };

    const nextProduct = () => {
        console.log({ selectedProduct });
        if (selectedProduct.navigationId < products.length) {
            selectedProduct = products[selectedProduct.navigationId];
        }
    };

    $: prevBtnDisabled = selectedProduct.navigationId == 1;
    $: nextBtnDisabled = selectedProduct.navigationId == products.length;

    // $: {
    //     console.log("---------- PRODUCT SELECTOR -----------");
    //     console.log({selectedProduct});
    // }
</script>

<section class="">
    {#if selectedProduct}
        <Flex direction="row" justify="start" class="pa-2 gap-1">
            <Flex direction="row">
                <Flex direction="row" class="gap-0">
                    <IconButton
                        title={$_("previous")}
                        size="button"
                        disabled={prevBtnDisabled}
                        on:click={prevProduct}
                    >
                        <Icon
                            class="pr-0 mr-0 pl-0 ml-1 {prevBtnDisabled
                                ? 'grey-text'
                                : 'tescoblue-text'} "
                            tag="svg"
                            viewBox="2 2 20 20"
                        >
                            <path fill="currentColor" d={mdiChevronLeft} />
                        </Icon>
                    </IconButton>

                    <SelectorProduct
                        bind:product={selectedProduct}
                        {products}
                    />

                    <IconButton
                        title={$_("next")}
                        size="button"
                        disabled={nextBtnDisabled}
                        on:click={nextProduct}
                    >
                        <Icon
                            class="pr-0 mr-0 pl-0 ml-1 {nextBtnDisabled
                                ? 'grey-text'
                                : 'tescoblue-text'}"
                            tag="svg"
                            viewBox="2 2 20 20"
                        >
                            <path fill="currentColor" d={mdiChevronRight} />
                        </Icon>
                    </IconButton>
                </Flex>
            </Flex>
        </Flex>
    {/if}
</section>

<style>
    section {
        width: fit-content;
    }
</style>
