<script lang="ts">
    import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
    import Button from "@smui/button";
    import IconButton, { Icon } from "@smui/icon-button";
    import Switch from "@smui/switch";
    import { mdiPencilBoxOutline, mdiDelete } from "@mdi/js";

    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";
    import { onMount } from "svelte";

    import { countries, unitList, productHierarchyList, temperatureList } from "../../../../stores/AppConfig";
    import {
        createProduct as apiCreateProduct,
        updateProduct as apiUpdateProduct,
        createProductDetail as apiCreateProductDetail,
        updateProductDetail as apiUpdateProductDetail,
        deleteProductDetail as apiDeleteProductDetail,
        createProductSupplier as apiCreateProductSupplier,
        updateProductSupplier as apiUpdateProductSupplier,
        deleteProductSupplier as apiDeleteProductSupplier,
    } from "../../../../api/Product";
    import ProductSupplierPopup from "./ProductSupplierPopup.svelte";
    import ProductDetailPopup from "./ProductDetailPopup.svelte";
    import DTInputCell from "../../common/DTInputCell.svelte";
    import DTSelectCell from "../../common/DTSelectCell.svelte";
    import DtSelectCellLazy from "../../common/DTSelectCellLazy.svelte";
    import ConfirmationDialog from "../../../dialogs/ConfirmationDialog.svelte";
    import { snackbarSuccess } from "../../../../stores/AppStatus";

    import { handleApiError } from "../../lib/errorHandler";

    export let productList;
    export let productData;
    export let productDetailList;
    export let productSupplierList;
    export let supplierLinkList;

    //    export let backClicked = false;
    export let pageToShow = true;
    export let editType = "add";

    // Product detail dialog
    let productDetailDialogOpen = false;
    let productDetailDialogType = "add";
    let productDetailData = {};

    // Supp detail dialog
    let productSupplierDialogOpen = false;
    let productSupplierDialogType = "add";
    let productSupplierData = {};

    // Delete dialogs
    let confirmDeleteProductDetailQuestion = "";
    let confirmDeleteProductSupplierQuestion = "";
    let showConfirmDeleteProductDetailDialog = false;
    let showConfirmDeleteProductSupplierDialog = false;
    let confirmationDialogData = {};
    let tableType = "";

    let buttonSaveDisabled = true;

    // Status change processing block
    let activeLbl = "active";

    // Lists for comboboxes
    let temperatureListLoc = [];
    let unitListLoc = [];
    let productHierarchyListLoc = [];

    // $: console.log({ productDetailData });

    onMount(() => {
        temperatureListLoc = $temperatureList
            .filter((e) => e.active)
            .map((x) => {
                return { id: x.id, name: x.description };
            });
        unitListLoc = $unitList
            .filter((e) => e.active)
            .map((x) => {
                return { id: x.code, name: x.description };
            });
        productHierarchyListLoc = $productHierarchyList.map((x) => {
            return { id: x.subGrpCode, name: x.subGrpDesc };
        });
    });

    const tableHeadProductDetail = [
        { columnId: "actions", visible: true, label: "", numeric: false, extraClasses: "" },
        { columnId: "id", visible: false, label: $_("id"), numeric: true, extraClasses: "" },
        { columnId: "country", visible: true, label: $_("country"), numeric: false, extraClasses: "" },
        { columnId: "tpnb", visible: true, label: $_("tpnb"), numeric: true, extraClasses: "" },
        { columnId: "ean", visible: true, label: $_("ean"), numeric: false, extraClasses: "" },
        { columnId: "description", visible: true, label: $_("description"), numeric: false, extraClasses: "" },
        { columnId: "descriptionEn", visible: true, label: $_("description_en"), numeric: false, extraClasses: "" },
        { columnId: "buyer", visible: true, label: $_("buyer"), numeric: false, extraClasses: "" },
/*   COD-4610         { columnId: "technicalManager", visible: true, label: $_("technical_manager"), numeric: false, extraClasses: "" }, */
        { columnId: "autobuy", visible: true, label: $_("autobuy"), numeric: false, extraClasses: "" },
        { columnId: "status", visible: true, label: $_("status"), numeric: false, extraClasses: "" },
    ];

    // Table Head
    const tableHeadProductSupplier = [
        { columnId: "actions", visible: true, label: "", numeric: false, extraClasses: "" },
        { columnId: "country", visible: true, label: $_("country"), numeric: false, extraClasses: "" },
        { columnId: "supplierId", visible: true, label: $_("ce_supplier_no"), numeric: true, extraClasses: "" },
        { columnId: "supplierName", visible: true, label: $_("ce_supplier_name"), numeric: false, extraClasses: "" },
        { columnId: "rmsSupplierNo", visible: true, label: $_("rms_supplier_no"), numeric: false, extraClasses: "" },
        { columnId: "rmsSupplierName", visible: true, label: $_("rms_supplier_name"), numeric: false, extraClasses: "" },
        { columnId: "currency", visible: true, label: $_("currency"), numeric: false, extraClasses: "" },
        { columnId: "status", visible: true, label: $_("status"), numeric: true, extraClasses: "" },
    ];

    $: productAvailableCountries = [...$countries.filter(c => (productDetailDialogType == 'edit' && c.id == productDetailData.country) || !productDetailList.map(x => x.country).includes(c.id))]
    $: addLinkBtnDisabled = (productDetailList.length == $countries.length);

    // Sort tables 1. CountryID, 2. SupplierID
    $: productDetailList = [...productDetailList].sort((a,b) => a.country[0].replace('C', '1').replace('S', '2').replace('H', '3') > b.country[0].replace('C', '1').replace('S', '2').replace('H', '3') ? 1 : -1);   
    $: productSupplierList = [...productSupplierList].sort((a,b) => [a.country[0].replace('C', '1').replace('S', '2').replace('H', '3'), a.supplierId].join(':') >
                                 [b.country[0].replace('C', '1').replace('S', '2').replace('H', '3'), b.supplierId].join(':') ? 1 : -1);
    // $: {
    //     console.log("productDetailList.length: ", productDetailList.length);
    //     console.log("$countries.length: ", $countries.length);
    //     console.log({addLinkBtnDisabled});
    //     console.log({productAvailableCountries});
    // }

    const handleEvents = (event) => {
        console.log("================= handleEvents ==============");
        console.log({ event });

        switch (event.type) {
            case "productDetailCancel":
                console.log("Cancel Product Detail");
                productDetailDialogOpen = false;
                break;
            case "productDetailSave":
                console.log("Save Product Detail");
                productDetailDialogOpen = false;
                console.log({ productDetailData });
                // Update Product Detail table on screen
                productDetailList.filter((x) => x.id == event.detail.id)[0] = event.detail;
                saveProductDetail(productDetailDialogType, event.detail);
                break;
            case "productSupplierCancel":
                console.log("Cancel Product Supplier");
                productSupplierDialogOpen = false;
                break;
            case "productSupplierSave":
                console.log("Save Product Supplier");
                productSupplierDialogOpen = false;
                console.log({ productSupplierData });
                // Update Product Supplier table on screen
                productSupplierList.filter((x) => x.id == event.detail.id)[0] = event.detail;
                saveProductSupplier(productSupplierDialogType, event.detail);
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    function enableSaveButton() {
        console.log("================= enableSaveButton ==============");
        buttonSaveDisabled = false;
    }

    function disableSaveButton() {
        console.log("================= disableSaveButton ==============");
        buttonSaveDisabled = true;
    }

    const saveProduct = (data) => {
        console.log("================= saveProduct ==============");
        var saveData = { ...data };
        disableSaveButton();

        if (editType == "add") {
            // Add New product
            data.status = true;
            saveData.status = data.status ? 1 : 0;
            apiCreateProduct(saveData)
                .then((res) => {
                    console.log({ res });
                    productData.id = res[0].id;
                    productData.status = true;
                    productData = { ...data };
                    productList.push(productData);
                    editType = "edit";
                    $snackbarSuccess.text = $_("product_created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
                });
        } else {
            // Update existing product
            saveData.status = data.status ? 1 : 0;
            apiUpdateProduct(saveData)
                .then((res) => {
                    console.log({ res });
                    // Update Product List table
                    var tmp = productList.filter((x) => x.id == data.id)[0];
                    productData = { ...data };
                    tmp.cartonLogistics = data.cartonLogistics;
                    tmp.description = data.description;
                    tmp.palletLogistics = data.palletLogistics;
                    tmp.status = data.status;
                    tmp.subGroupCode = data.subGroupCode;
                    tmp.temperature = data.temperature;
                    tmp.units = data.units;
                    productList = productList;
                    $snackbarSuccess.text = $_("product_updated");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
                });
        }
    };

    const saveProductDetail = (type, data) => {
        console.log("================= saveProductDetail ==============");

        var saveData = { ...data };
        if (type == "add") {
            // Add New Product Detail
            saveData.autobuy = data.autobuy ? 1 : 0;
            saveData.status = data.status ? 1 : 0;
            console.log({ saveData });
            apiCreateProductDetail(saveData)
                .then((res) => {
                    console.log({ res });
                    data.id = res[0].id;
                    productDetailList.push(data);
                    productDetailList = productDetailList;
                    $snackbarSuccess.text = $_("product_detail_created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log("Error:", {error});
                    if (!handleApiError($_("create_failed", { values: { reason: [error.message, error.cause.response.data.payload.join(" ")].join(": ") } }), error, 'non-fatal')) throw(error);
                });
        } else {
            // Update existing product detail
            saveData.autobuy = data.autobuy ? 1 : 0;
            saveData.status = data.status ? 1 : 0;
            console.log({ saveData });
            apiUpdateProductDetail(saveData)
                .then((res) => {
                    console.log({ res });
                    // Update Product Detail List table
                    var tmp = productDetailList.filter((x) => x.id == data.id)[0];
                    tmp.id = data.id;
                    tmp.productId = data.productId;
                    tmp.country = data.country;
                    tmp.tpnb = data.tpnb;
                    tmp.ean = data.ean;
                    tmp.description = data.description;
                    tmp.descriptionEn = data.descriptionEn;
                    tmp.autobuy = data.autobuy;
                    tmp.buyer = data.buyer;
                    tmp.technicalManager = data.technicalManager;
                    tmp.status = data.status;
                    productDetailList = productDetailList;
                    $snackbarSuccess.text = $_("product_detail_updated");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
                });
        }
    };

    const saveProductSupplier = (type, data) => {
        console.log("================= saveProductSupplier ==============");
        var saveData = { ...data };

        if (type == "add") {
            // Add New product supplier
            saveData.status = data.status ? 1 : 0;
            apiCreateProductSupplier(saveData)
                .then((res) => {
                    console.log({ res });
                    data.id = res[0].id;
                    productSupplierList.push(data);
                    productSupplierList = productSupplierList;
                    $snackbarSuccess.text = $_("product_supplier_created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
                });
        } else {
            // Update existing product supplier
            saveData.status = data.status ? 1 : 0;
            apiUpdateProductSupplier(saveData)
            .then((res) => {
                console.log({res});
                // Update Product Supplier List table
                var tmp = productSupplierList.filter((x) => x.id == data.id)[0];
                tmp.id = data.id;
                tmp.productId = data.productId;
                tmp.country = data.country;
                tmp.supplierId = data.supplierId;
                tmp.supplierLinkId = data.supplierLinkId;
                tmp.rmsSupplierNo = data.rmsSupplierNo;
                tmp.rmsSupplierName = data.rmsSupplierName;
                tmp.supplierName = data.supplierName;
                tmp.currency = data.currency;
                tmp.status = data.status;
                productSupplierList = productSupplierList;
                $snackbarSuccess.text = $_("product_supplier_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            })
        }
    };

    function editProductDetail(data, type = "add") {
        console.log(type, "=========== editProductDetail ==============");
        console.log({ data });
        console.log({ productDetailData });
        if (type == "add") {
            console.log("add?");
            productDetailData = {
                id: null,
                productId: productData.id,
                country: null,
                tpnb: null,
                ean: "",
                description: "",
                descriptionEn: "",
                autobuy: false,
                buyer: null,
                technicalManager: null,
                status: true,
            };
        } else {
            console.log(data.tpnb);
            productDetailData = {
                id: data.id,
                productId: data.productId,
                country: data.country,
                tpnb: data.tpnb,
                ean: data.ean,
                description: data.description,
                descriptionEn: data.descriptionEn,
                autobuy: data.autobuy,
                buyer: data.buyer,
                technicalManager: data.technicalManager,
                status: data.status,
            };
        }
        console.log({...productDetailData });
        productDetailDialogOpen = true;
    }

    function editProductSupplier(data = null) {
        console.log("================= editProductSupplier ==============");
        if (productSupplierDialogType == "add") {
            productSupplierData = {
                id: null,
                productId: productData.id,
                country: null,
                supplierId: null,
                supplierLinkId: null,
                rmsSupplierNo: "",
                rmsSupplierName: "",
                supplierName: null,
                currency: "",
                status: true,
            };
        } else {
            productSupplierData = { ...data };
        }

        productSupplierDialogOpen = true;
        console.log({ productSupplierData });
    }

    function changeProductDetailAutobuy(productId, newStatus) {
        console.log("================= changeProductDetailAutobuy ==============");
        let tmp = productDetailList.filter((x) => x.id == productId)[0];
        saveProductDetail("edit", tmp);
    }

    function changeProductDetailStatus(productId, newStatus) {
        console.log("================= changeProductDetailStatus ==============");
        let tmp = productDetailList.filter((x) => x.id ==productId)[0];
        saveProductDetail("edit", tmp);
    }

    function changeProductSupplierStatus(supplierId, newStatus) {
        console.log("================= changeProductSupplierStatus ==============");
        var tmp = productSupplierList.filter((x) => x.id == supplierId)[0];
        saveProductSupplier("edit", tmp);
    }

    const deleteProductDetail = (id) => {
        console.log("================= deleteProductDetail ==============");
        showConfirmDeleteProductDetailDialog = false;
        apiDeleteProductDetail(id)
            .then((res) => {
                console.log({ res });
                productDetailList = [...productDetailList.filter((x) => x.id != id)];
                $snackbarSuccess.text = $_("product_detail_deleted");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log({ error });
                if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            });
    };

    const deleteProductSupplier = (id) => {
        console.log("================= deleteProductSupplier ==============");
        showConfirmDeleteProductSupplierDialog = false;
        apiDeleteProductSupplier(id)
            .then((res) => {
                console.log({ res });
                productSupplierList = [...productSupplierList.filter((x) => x.id != id)];
                $snackbarSuccess.text = $_("product_supplier_deleted");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log({ error });
                if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            });
    };

    const showDeleteProductDetailConfirmation = (data) => {
        console.log("================= showDeleteProductDetailConfirmation ==============");
        confirmationDialogData = { id: data.id };
        confirmDeleteProductDetailQuestion = $_("do_you_want_delete_product_detail", { values: { country: data.country, tpnb: data.tpnb, description: data.description } });
        showConfirmDeleteProductDetailDialog = true;
    };

    const showDeleteProductSupplierConfirmation = (data) => {
        console.log("================= showDeleteProductSupplierConfirmation ==============");
        confirmationDialogData = { id: data.id };
        confirmDeleteProductSupplierQuestion = $_("do_you_want_delete_product_supplier", {
            values: { country: data.country, rmsSupplierNo: data.rmsSupplierNo, rmsSupplierName: data.rmsSupplierName },
        });
        showConfirmDeleteProductSupplierDialog = true;
    };
</script>

<section class="header w-100 top-splitter bottom-splitter">
    <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1 pt-3 pb-3 pl-3" justify="between">
        <Flex direction="row" class="gap-1 w-100">
            <Button
                on:click={() => {
                    pageToShow = true;
                    console.log({ pageToShow });
                }}
                class="tescoblue white-text pr-4 nowrap"
                title={$_("back")}
            >
                <Icon class="material-icons">arrow_back_ios_new</Icon>
                {$_("back")}
            </Button>
            <div class="w-100 font-weight-bold">
                {editType == "add" ? $_("add_new_product") : $_("edit_product_detail")}
            </div>
        </Flex>
    </Flex>
</section>

<section class="data w-100 tescoblue-border-top-thin pb-2">
    <Flex direction="column" align="start" class="gap-1">
        <Flex justify="start" align="end" class="gap-1">
            <DataTable class="input-form no-border grey lighten-3" table$aria-label="CE Product detail">
                <Body>
                    {#if editType == "edit"}
                        <Row>
                            <Cell class="form-label">{$_("tpn_en")}</Cell>
                            <DTInputCell class="grey lighten-2" bind:value={productData.id} editable={false} numeric={false} placeholder={$_("automatically_generated")} />
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("product_name_en")}</Cell>
                        <DTInputCell class="white" bind:value={productData.description} editable={true} numeric={false} on:change={() => enableSaveButton()} />
                    </Row>
                    <Row>
                        <Cell class="form-label">{$_("carton_logistics")}</Cell>
                        <DTInputCell class="white" bind:value={productData.cartonLogistics} editable={true} numeric={false} on:change={() => enableSaveButton()} />
                    </Row>
                    <Row>
                        <Cell class="form-label">{$_("pallet_logistics")}</Cell>
                        <DTInputCell class="white" bind:value={productData.palletLogistics} editable={true} numeric={false} on:change={enableSaveButton} />
                    </Row>
                    <Row>
                        <Cell class="form-label">{$_("temperature")}</Cell>
                        {#if Array.isArray(temperatureListLoc) && temperatureListLoc.length > 0}
                            <DTSelectCell
                                bind:value={productData.temperature}
                                list={temperatureListLoc}
                                format={{ showId: false, translateName: true }}
                                on:change={enableSaveButton}
                            />
                        {/if}
                    </Row>
                    <Row>
                        <Cell class="form-label">{$_("units_of_sale")}</Cell>
                        {#if Array.isArray(unitListLoc) && unitListLoc.length > 0}
                            <DTSelectCell
                                bind:value={productData.units}
                                list={unitListLoc}
                                format={{ showId: false, translateName: true }}
                                on:change={enableSaveButton}
                            />
                        {/if}
                    </Row>
                    <Row>
                        <Cell class="form-label">{$_("sub_group_code")}</Cell>
                        {#if Array.isArray(productHierarchyListLoc) && productHierarchyListLoc.length > 0}
                            <DtSelectCellLazy
                                bind:value={productData.subGroupCode}
                                list={productHierarchyListLoc}
                                format={{ showId: true, translateName: true }}
                                on:SMUISelect:change={enableSaveButton}
                            />
                        {/if}
                    </Row>
                    {#if editType == "edit"}
                        <Row>
                            <Cell class="form-label">{$_("status")}</Cell>
                            <Cell class="white">
                                <Switch bind:checked={productData.status} on:click={enableSaveButton} />
                            </Cell>
                        </Row>
                    {/if}
                </Body>
            </DataTable>
            <Button
                disabled={buttonSaveDisabled}
                on:click={saveProduct(productData)}
                class={!buttonSaveDisabled ? "tescoblue white-text pr-4 nowrap" : "grey white-text"}
                title={$_("save")}
            >
                <Icon class="material-icons">save</Icon>
                {$_("save")}
            </Button>
        </Flex>

        <hr class="w-100 bs-bb" style="color: var(--mdc-theme-primary)" />

        <!-- Product Detail Table -->
        <Button
            disabled={addLinkBtnDisabled}
            title={$_("linkItem")}
            class="{(addLinkBtnDisabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined')} pr-4 nowrap ml-2"
            on:click={() => {
                productDetailDialogType = "add";
                editProductDetail(null, productDetailDialogType);
            }}
        >
            <Icon class="material-icons">add_circle</Icon>
            {$_("linkItem")}
        </Button>

        <DataTable stickyHeader table$aria-label="Item list" style="width: 100%;">
            <Head>
                <Row>
                    {#each tableHeadProductDetail as head (head.columnId)}
                        {#if head.visible}
                            <Cell class="datatable-header">
                                {$_(head.label)}
                            </Cell>
                        {/if}
                    {/each}
                </Row>
            </Head>
            <Body>
                {#if productDetailList && productDetailList.length > 0}
                    {#each productDetailList as productDetail (productDetail.id)}
                        <Row>
                            {#each tableHeadProductDetail as head (head.columnId)}
                                {#if head.visible}
                                    <Cell>
                                        {#if head.columnId === "actions"}
                                            <!-- Edit button -->
                                            <IconButton
                                                title={$_("edit")}
                                                size="button"
                                                class="tescoblue-text"
                                                on:click={() => {
                                                    productDetailDialogType = "edit";
                                                    editProductDetail(productDetail, productDetailDialogType);
                                                    console.log("BUTTON edit: ", productDetail);
                                                }}
                                            >
                                                <svg viewBox="0 0 24 24" class="tescoblue-text">
                                                    <path fill="currentColor" d={mdiPencilBoxOutline} />
                                                </svg>
                                            </IconButton>
                                            <!-- Delete button -->
                                            <IconButton
                                                size="button"
                                                class="tescoblue-text opacity-1"
                                                title={$_("delete")}
                                                on:click={() => {
                                                    tableType = "product";
                                                    showDeleteProductDetailConfirmation(productDetail);
                                                }}
                                            >
                                                <svg viewBox="0 0 24 24" class="pa-0 ma-0">
                                                    <path fill="currentColor" d={mdiDelete} />
                                                </svg>
                                            </IconButton>
                                        {:else if head.columnId === "autobuy"}
                                            <!-- Autobuy Switch -->
                                            <Switch bind:checked={productDetail.autobuy}
                                                    on:SMUISwitch:change={(e) => {changeProductDetailAutobuy(productDetail.id, e.detail.selected)}} />
                                        {:else if head.columnId === "status"}
                                            <!-- Status Switch -->
                                            <Switch bind:checked={productDetail.status}
                                                    on:SMUISwitch:change={(e) => {changeProductDetailStatus(productDetail.id, e.detail.selected)}} />
                                        {:else}
                                            {productDetail[head.columnId]}
                                        {/if}
                                    </Cell>
                                {/if}
                            {/each}
                        </Row>
                    {/each}
                {:else}
                    <Row class="datatable-content">
                        <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data")}</Cell>
                    </Row>
                {/if}
            </Body>
        </DataTable>

        <hr class="w-100 bs-bb" style="color: var(--mdc-theme-primary)" />

        <!-- CE Supplier Table -->
        <Button
            disabled={editType == "add"}
            title={$_("linkcesupp")}
            class="tescoblue-text white-tescoblue-outlined pr-4 nowrap ml-2"
            on:click={() => {
                productSupplierDialogType = "add";
                editProductSupplier(null);
            }}
        >
            <Icon class="material-icons">add_circle</Icon>
            {$_("linkcesupp")}
        </Button>

        <DataTable stickyHeader table$aria-label="Ce local supp list" style="width: 100%;" bind:productSupplierList>
            <Head>
                <Row>
                    {#each tableHeadProductSupplier as head (head.columnId)}
                        {#if head.visible}
                            <Cell class="datatable-header">
                                {$_(head.label)}
                            </Cell>
                        {/if}
                    {/each}
                </Row>
            </Head>
            <Body>
                {#if productSupplierList.length > 0}
                    {#each productSupplierList as productSupplier (productSupplier.id)}
                        <Row>
                            {#each tableHeadProductSupplier as head (head.columnId)}
                                {#if head.visible}
                                    <Cell>
                                        {#if head.columnId === "actions"}
                                            <!-- Delete button -->
                                            <IconButton
                                                size="button"
                                                class="tescoblue-text opacity-1"
                                                title={$_("delete")}
                                                on:click={() => showDeleteProductSupplierConfirmation(productSupplier)}
                                            >
                                                <svg viewBox="0 0 24 24" class="pa-0 ma-0">
                                                    <path fill="currentColor" d={mdiDelete} />
                                                </svg>
                                            </IconButton>
                                        {:else if head.columnId === "status"}
                                            <!-- Status Switch -->
                                            <Switch bind:checked={productSupplier.status}
                                                    on:SMUISwitch:change={(e) => {changeProductSupplierStatus(productSupplier.id, e.detail.selected)}}
                                            />
                                        {:else}
                                            {productSupplier[head.columnId]}
                                        {/if}
                                    </Cell>
                                {/if}
                            {/each}
                        </Row>
                    {/each}
                {:else}
                    <Row class="datatable-content">
                        <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data")}</Cell>
                    </Row>
                {/if}
            </Body>
        </DataTable>
    </Flex>

    <!-- Local Product Dialog -->
    {#key productDetailDialogOpen}
        <ProductDetailPopup
            open={productDetailDialogOpen}
            type={productDetailDialogType}
            data={productDetailData}
            availableCountries={productAvailableCountries}
            on:productDetailCancel={handleEvents}
            on:productDetailSave={handleEvents}
        />
    {/key}
    <!-- Product Supplier Dialog -->
    <ProductSupplierPopup
        open={productSupplierDialogOpen}
        type={productSupplierDialogType}
        data={productSupplierData}
        {supplierLinkList}
        alreadyLinkedSuppliers="{productSupplierList.map(x => x.country + '-' + x.rmsSupplierNo)}"
        on:productSupplierCancel={handleEvents}
        on:productSupplierSave={handleEvents}
    />

    <!-- Confirm Delete Product Detail Dialog -->
    <ConfirmationDialog
        open={showConfirmDeleteProductDetailDialog}
        title={$_("delete")}
        noBtnText={$_("no")}
        yesBtnText={$_("yes")}
        big={false}
        headerColors="tescoblue white-text"
        on:reject={() => {
            showConfirmDeleteProductDetailDialog = false;
        }}
        on:confirm={() => deleteProductDetail(confirmationDialogData.id)}
    >
        {confirmDeleteProductDetailQuestion}
    </ConfirmationDialog>

    <!-- Confirm Delete Product Supplier Dialog -->
    <ConfirmationDialog
        open={showConfirmDeleteProductSupplierDialog}
        title={$_("delete")}
        noBtnText={$_("no")}
        yesBtnText={$_("yes")}
        big={false}
        headerColors="tescoblue white-text"
        on:reject={() => {
            showConfirmDeleteProductSupplierDialog = false;
        }}
        on:confirm={() => deleteProductSupplier(confirmationDialogData.id)}
    >
        {confirmDeleteProductSupplierQuestion}
    </ConfirmationDialog>
</section>
