<script lang="ts">
    import { isActionEnabled } from "./lib/Functions";
    import { onMount, createEventDispatcher } from "svelte";

    import PurchaseScreen from "./PurchaseScreen.svelte";

    import { handleApiError } from "./lib/errorHandler";

    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiCartArrowDown, mdiCheck, mdiCancel } from "@mdi/js";
    import FormField from "@smui/form-field";
    import Checkbox from "@smui/checkbox";
    import WeekInfo from "./common/WeekInfo.svelte";
    import GetDataButton from "./common/GetDataButton.svelte";
    import ExportToExcelButton from "./common/ExportToExcelButton.svelte";
    import BSFilterBar from "./BuyingScreen/BSFilterBar.svelte";
    import BSDataTable from "./BuyingScreen/BSDataTable.svelte";
    import BSEditStatusPopup from "./BuyingScreen/BSEditStatusPopup.svelte";
    import {
        getForecast as apiGetForecast,
        updateForecast as apiUpdateForecast,
    } from "../../api/Forecast";
    import { updateProductDetail as apiUpdateProductDetail } from "../../api/Product";
    import {
        confirmForecastPurchase as apiConfirmForecastPurchase,
        purchaseAutobuy as apiPurchaseAutobuy,
    } from "../../api/Purchase";
    import { getUserManagement } from "../../api/User.js";
    import { createNotificationJob as apiCreateNotificationJob } from "../../api/Notification";
    import ChangeWeekStatusButton from "./common/ChangeWeekStatusButton.svelte";
    import {
        updateWeekStatus as apiUpdateWeekStatus,
        getWeek as apiGetWeek,
    } from "../../api/Weeks";

    import {
        authenticatedUser,
        snackbarSuccess,
        snackbarWarning,
        snackbarError,
        apiError,
        apiErrorDetails,
        selectedWeek,
        buyingScreenState,
    } from "../../stores/AppStatus";
    import Paper from "@smui/paper";
    import FullscreenLoader from "../elementary/FullscreenLoader.svelte";

    import {
        dcList,
        forecastStatuses,
        enableNotifications,
    } from "../../stores/AppConfig";
    import { bsDcList } from "../../stores/FiltersLists.js";
    import type { BuyingListItems } from "../../types/BuyingList";
    import ConfirmationDialog from "../dialogs/ConfirmationDialog.svelte";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";
    import { generalExportToExcel as apiGeneralExportToExcel } from "../../api/Report";

    export let selectedDC = [];
    export let autoLoadData = false;
    // Search
    let fileNameSearch = "";
    let tpnbSearch = "";
    let ceTpnSearch = "";
    let tpnbDescSearch = "";
    let ceDescSearch = "";
    let statusSearchArray = [];
    let dataLoaded = false;
    let loadingData = false;
    let buyerList = [];
    let dcSearchArray = [];
    let buyerSearchArray = [];
    let confirmedSearchArray = [];
    let autobuySearchArray = [];
    let reasonSearchArray = [];

    // Loading...
    let apiCallProcessing = false;
    let apiCallProcessingMessage = "";

    let fcDialogOpen = false;
    let bsItems: BuyingListItems[] = [];
    let item: BuyingListItems;
    let filteredItems: BuyingListItems[] = [];
    let filteredProducts = [];
    let selectedItems = [];

    let moduleToShow = "buing_screen";
    let productData = {};
    let products = [];
    let selectedProduct = {};

    let popupOpen = false;
    let popupType = "edit_row";
    let popupData = null;
    let popupAvailableStatuses = [];
    let popupStatus;
    let popupReasonCode;
    let popupComment;
    let popupTitle;
    let popupAction;
    let dcSearch;
    let confirmationOpen;
    let confirmationText;
    let confirmationAction;
    let changeWeekStatusDialogOpen = false;
    let confirmChangeWeekNotifyUsers = false;
    let confirmDialogOpen = false;
    let confirmDialogTitle = "";
    let confirmDialogText = "";
    let confirmDialogType = "all";
    let confirmDialogValueConfirmable;
    let confirmDialogValueNotConfirmable;
    let confirmDialogData: BuyingListItems[] = [];

    // Screen action permisssions
    let canInsert = false;
    let canUpdate = false;
    let canDelete = false;

    let forecastStatusesArray = $forecastStatuses.map((x) => {
        return { id: x.name, name: $_(x.name) };
    });

    const dispatch = createEventDispatcher();

    $: if (autoLoadData) {
        loadBSData();
        autoLoadData = false;
    }

    $: console.log({ buyerSearchArray });

    function filterAvailableStatuses() {
        console.log("================= filterAvailableStatuses ==============");
        console.log({ forecastStatusesArray });
        return forecastStatusesArray;
    }

    function showEditStatusPopup(
        action,
        type,
        data,
        title,
        availStatuses,
        status,
        reasonCode,
        comment,
    ) {
        console.log("================= showEditStatusPopup ==============");
        console.log({ action });
        console.log({ type });
        console.log({ data });
        console.log({ title });
        console.log({ availStatuses });
        console.log({ status });
        console.log({ reasonCode });
        console.log({ comment });
        popupType = type;
        popupData = data;
        popupAction = action;
        popupTitle = title;
        popupAvailableStatuses = availStatuses;
        popupStatus = status;
        popupReasonCode = action == "reject" ? null : reasonCode;
        popupComment = comment;
        console.log({ popupReasonCode });
        popupOpen = true;
    }

    const handleBuyingScreenEvents = (event) => {
        console.log(
            "================= handleBuyingScreenEvents ==============",
        );
        console.log({ event });

        switch (event.type) {
            case "buyingScreenLoadData":
                console.log("EVENT Buying screen Edit row");
                loadBSData();
                break;
            case "buyingScreenBatchEditStatusEdit":
                console.log("EVENT Buying screen Batch Edit");
                console.log({ selectedItems });
                if (selectedItems.length > 0) {
                    editStatus("batch", selectedItems);
                } else {
                    $snackbarError.text = $_("no_rows_are_selected");
                    $snackbarError.element && $snackbarError.element.open();
                }
                break;
            case "buyingScreenRowEditStatusEdit":
                console.log("EVENT Buying screen Row Edit");
                editStatus("row", event.detail);
                break;
            case "popupCancel":
                console.log("EVENT Buying screen Popup Cancel");
                popupOpen = false;
                break;
            case "popupSave":
                console.log("EVENT Buying screen Popup Save");
                popupOpen = false;
                if (event.detail.type == "row") {
                    updateBSRow(
                        event.detail.data,
                        event.detail.status,
                        event.detail.reasonCode,
                        event.detail.comment,
                    );
                } else {
                    batchUpdateBSRows(
                        event.detail.data,
                        event.detail.status,
                        event.detail.reasonCode,
                        event.detail.comment,
                    );
                }
                break;
            case "buyingScreenChangeAutobuyStatus":
                console.log("EVENT Buying screen Change Autobuy Status");
                updateAutobuy(
                    event.detail.productDetailId,
                    !event.detail.autobuy,
                );
                break;
            case "purchaseItem":
                console.log("EVENT Switch to Purchase screen");
                productData = event.detail;
                console.log({ productData });
                console.log({ products });

                // Filtering products for PS
                filteredProducts = products
                    .filter((product) =>
                        filteredItems.some(
                            (filteredItem) =>
                                filteredItem.productId === product.productId,
                        ),
                    )
                    .map((product, index) => ({
                        ...product,
                        navigationId: index + 1, // Add a new navigationId field
                    }));

                // Selecting the product based on productId and setting the navigationId
                selectedProduct = filteredProducts.find(
                    (x) => x.productId === productData.productId,
                );

                console.log({ filteredProducts, selectedProduct });
                $buyingScreenState.lastBuyedRow = productData.id;
                $buyingScreenState.scrollPosition =
                    document.querySelector("#tab-content").scrollTop;
                document.querySelector("#tab-content").scrollTop = 0;
                moduleToShow = "purchase_screen";
                break;
            case "goBack":
                console.log("EVENT Switch back to Buying screen");
                productData = {};
                moduleToShow = "buing_screen";
                break;
            case "updateForecastStatus":
                console.log("EVENT Update Forecast Status");
                updateForecastStatus(event.detail);
                break;
            case "confirmItem":
                console.log("EVENT Item confirmed", event.detail);
                refreshBSProductData(event.detail?.item?.productId);
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    const loadBSData = async () => {
        console.log("================= loadBSData ==============");
        loadingData = true;
        try {
            // const weekData = await apiGetWeek($selectedWeek.id);

            const res = await apiGetForecast(
                null,
                $selectedWeek.tesco_year,
                $selectedWeek.tesco_week,
                null /*dcSearch.map(x => {return {id: x.id}.id}).join(',').toUpperCase()*/,
                null /*warehouseId*/,
                null /*productId*/,
                null /*productDetailId*/,
                null /*productDesc*/,
                null /*tpnb*/,
                null /*description*/,
                null /*ean*/,
                null /*buyer*/,
                null /*technicalManager*/,
                null /*note*/,
                null /*status*/,
                null /*reasonCode*/,
                null /*fileName*/,
                null /*purchaseConfirmed*/,
            );

            console.log({ res });

            if (res.length == 0) {
                console.log("getBSData().empty");
                bsItems = [];
            } else {
                res.forEach((x) => {
                    //x.productId = x.productId;
                    x.tpnb = String(x.tpnb);
                    x.selected = false;
                    x.autobuy = x.autobuy == 1;
                    x.warehouseCode = [...$dcList].filter(
                        (dc) => dc.id == x.warehouseId,
                    )[0]?.code;
                    x.countryOrder = x.country
                        .at(0)
                        .replace("C", "1")
                        .replace("S", "2")
                        .replace("H", "4");
                });
                bsItems = [
                    ...res.sort((a, b) =>
                        [a.descriptionEn, a.countryOrder, a.warehouseCode]
                            .join("#")
                            .toLowerCase() >
                        [b.descriptionEn, b.countryOrder, b.warehouseCode]
                            .join("#")
                            .toLowerCase()
                            ? 1
                            : -1,
                    ),
                ];
                console.log({ bsItems });

                // $divisionsList = [...divsArray];
                bsItems = bsItems;
                console.log({ bsItems });
                let i = 1;
                let productMap = new Map();
                bsItems.forEach((p) => {
                    if (!productMap.has(p.productId)) {
                        let tpnbMap = new Map(),
                            forecastsMap = new Map();

                        tpnbMap.set(p.country, p.tpnb);
                        forecastsMap.set(p.id, p.purchaseConfirmed == "Y");

                        productMap.set(p.productId, {
                            id: i++,
                            productId: p.productId,
                            productNameEn: p.descriptionEn,
                            tpnbs: tpnbMap,
                            forecasts: forecastsMap,
                            resolved: ![...forecastsMap.values()].includes(
                                false,
                            ),
                        });
                    } else {
                        productMap
                            .get(p.productId)
                            .tpnbs.set(p.country, p.tpnb);
                        productMap
                            .get(p.productId)
                            .forecasts.set(p.id, p.purchaseConfirmed == "Y");
                        productMap.get(p.productId).resolved = ![
                            ...productMap.get(p.productId).forecasts.values(),
                        ].includes(false);
                    }
                });

                console.log({ productMap });

                products = [...productMap.values()];
                console.log({ products });

                // products = bsItems.map(p => {
                //     return {
                //         id: i++,
                //         tpnb: p.tpnb,
                //         productId: p.productId,
                //         productNameEn: p.descriptionEn,
                //         forecastId: p.id,
                //         resolved: (p.purchaseConfirmed == 'Y')
                //     }
                // })
            }
            dispatch("getDataRequest");
            dataLoaded = true;
            loadingData = false;
        } catch (error) {
            console.log("getBSData().error");
            console.log(error);
            if (!handleApiError($_("get_data_failed"), error, "non-fatal"))
                throw error;
        }
    };

    // Reload product data after confirmation
    const refreshBSProductData = async (productId) => {
        console.log("================= refreshBSProductData ==============");
        console.log("productId: ", productId);

        if (!productId) {
            return;
        }

        loadingData = true;
        try {
            const res = await apiGetForecast(
                null,
                $selectedWeek.tesco_year,
                $selectedWeek.tesco_week,
                null /*dcSearch.map(x => {return {id: x.id}.id}).join(',').toUpperCase()*/,
                null /*warehouseId*/,
                productId /*productId*/,
                null /*productDetailId*/,
                null /*productDesc*/,
                null /*tpnb*/,
                null /*description*/,
                null /*ean*/,
                null /*buyer*/,
                null /*technicalManager*/,
                null /*note*/,
                null /*status*/,
                null /*reasonCode*/,
                null /*fileName*/,
                null /*purchaseConfirmed*/,
            );
            let productFcData = [...res];
            console.log({ productFcData });

            if (productFcData.length > 0) {
                bsItems.forEach((bsItem) => {
                    let fcRow = productFcData.find((r) => r.id == bsItem.id);
                    if (fcRow) {
                        bsItem.purchaseConfirmed = fcRow.purchaseConfirmed;
                        products.forEach((p) => {
                            if (p.productId == fcRow.productId) {
                                p.forecasts.set(
                                    fcRow.id,
                                    fcRow.purchaseConfirmed == "Y",
                                );
                                p.resolved = ![
                                    ...p.forecasts.values(),
                                ].includes(false);
                            }
                        });
                    }
                });

                bsItems = bsItems;
                products = products;
            }

            selectedProduct = products
                .filter((x) => x.productId == productId)
                .at(0);

            dataLoaded = true;
            loadingData = false;
        } catch (error) {
            console.log("refreshBSProductData().error");
            console.log(error);
            if (!handleApiError($_("get_data_failed"), error, "non-fatal"))
                throw error;
        }
    };

    const updateForecastStatus = async (fcData) => {
        console.log("updateForecastStatus(): ", { fcData });

        let productId;
        let purchaseConfirmed;
        bsItems.forEach((bsItem) => {
            if (bsItem.id == fcData.id) {
                bsItem.status = fcData.status ? fcData.status : bsItem.status;
                bsItem.reasonCode = fcData?.reasonCode
                    ? fcData.reasonCode
                    : bsItem.reasonCode;
                bsItem.userComment = fcData?.userComment
                    ? fcData.userComment
                    : bsItem.userComment;
                bsItem.purchaseConfirmed = fcData?.purchaseConfirmed
                    ? fcData?.purchaseConfirmed
                    : bsItem.purchaseConfirmed;
                bsItem.purchasedVol =
                    fcData?.purchasedVol || fcData?.purchasedVol == 0
                        ? fcData?.purchasedVol
                        : bsItem.purchasedVol;
                if (fcData?.autobuyStatus) {
                    bsItem.autobuyStatus = fcData.autobuyStatus;
                }
                if (fcData?.autobuyStatusMessage) {
                    bsItem.autobuyDetail = fcData.autobuyStatusMessage;
                }
                productId = bsItem.productId;
                purchaseConfirmed = bsItem.purchaseConfirmed;
            }
        });
        console.log({ bsItems });
        bsItems = bsItems;

        products.forEach((p) => {
            if (p.productId == productId) {
                p.forecasts.set(fcData.id, purchaseConfirmed == "Y");
                p.resolved = ![...p.forecasts.values()].includes(false);
            }
        });

        selectedProduct = products
            .filter((x) => x.productId == productId)
            .at(0);

        console.log({ products });
        products = products;
    };

    function editStatus(type, data) {
        console.log("================= editStatus ==============");
        console.log({ type });
        console.log({ data });
        if (type == "row") {
            showEditStatusPopup(
                "edit",
                "row",
                data,
                "edit_status",
                filterAvailableStatuses(),
                data.status,
                data.reasonCode,
                data.userComment,
            );
        } else {
            showEditStatusPopup(
                "edit",
                "batch",
                selectedItems,
                "batch_edit_status",
                filterAvailableStatuses(),
                null,
                null,
                null,
            );
        }
    }

    async function performAction(action) {
        console.log("================= performAction ==============");
        console.log({ action });
        console.log({ selectedItems });
        if (selectedItems.length == 0) {
            confirmationAction = action;
            confirmationText =
                "no_rows_selected_peform_action_on_all_applicable_rows";
            confirmationOpen = true;
        } else {
            switch (action) {
                case "purchase":
                    console.log("Confirmed Purchase for All selected rows");
                    //refreshTableData();
                    batchConfirmPurchase(selectedItems);
                    break;
                case "autobuy":
                    console.log("Confirmed Autobuy for All selected rows");
                    batchAutobuy(selectedItems);
                    break;
                case "reject":
                    console.log("Confirmed Reject for All selected rows");
                    batchReject(selectedItems);
                    break;
                default:
                    console.log("Unknown action: " + action);
            }
        }
    }

    async function batchActionConfirmed(action) {
        console.log("================= batchActionConfirmed ==============");
        console.log({ action });
        console.log({ filteredItems });
        switch (action) {
            case "purchase":
                console.log("Confirmed Purchase for All filtered rows");
                //refreshTableData();
                batchConfirmPurchase(filteredItems);
                break;
            case "autobuy":
                console.log("Confirmed Autobuy for All filtered rows");
                batchAutobuy(filteredItems);
                break;
            case "reject":
                console.log("Confirmed Reject for All filtered rows");
                batchReject(filteredItems);
                break;
            default:
                console.log("Unknown action: " + action);
        }
    }

    async function refreshTableData() {
        console.log("================= refreshTableData ==============");
        const refreshData = await apiGetForecast(
            null,
            $selectedWeek.tesco_year,
            $selectedWeek.tesco_week,
            null /*dcSearch.map(x => {return {id: x.id}.id}).join(',').toUpperCase()*/,
            null /*warehouseId*/,
            null /*productId*/,
            null /*productDetailId*/,
            null /*productDesc*/,
            null /*tpnb*/,
            null /*description*/,
            null /*ean*/,
            null /*buyer*/,
            null /*technicalManager*/,
            null /*note*/,
            null /*status*/,
            null /*reasonCode*/,
            null /*fileName*/,
            null /*purchaseConfirmed*/,
        );
        refreshData.forEach((x) => {
            let row = [...bsItems].filter((r) => r.id == x.id).at(0);
            row.purchaseConfirmed = x.purchaseConfirmed;
            row.status = x.status;
            row.totalVol = x.totalVol;
            row.purchasedVol = x.purchasedVol;
            console.log({ row });
        });
        console.log({ bsItems });
        console.log({ filteredItems });
    }

    function batchConfirmPurchase(data) {
        console.log("================= batchConfirmPurchase ==============");
        console.log({ data });
        let rowsToConfirm = [...data].filter(
            (r) =>
                r.purchaseConfirmed == "N" &&
                ["purchased", "purchased_less", "purchased_more"].includes(
                    r.status,
                ) &&
                (r.canBuy == "Y" || $authenticatedUser.isAdmin),
        );
        console.log({ rowsToConfirm });
        if (rowsToConfirm.length > 0) {
            if (rowsToConfirm.length == data.length) {
                // All rows can be confirmed
                confirmDialogTitle = "confirm_all_rows";
                confirmDialogText = "do_yo_want_to_confirm_all_rows";
                confirmDialogType = "all";
                confirmDialogValueConfirmable = rowsToConfirm.length;
                confirmDialogValueNotConfirmable = null;
            } else {
                // Not all rows can be confirmed
                confirmDialogTitle = "partially_confirm_rows";
                confirmDialogText =
                    "some_rows_cant_be_confirmed_do_you_want_confirm_anyway";
                confirmDialogType = "notAll";
                confirmDialogValueConfirmable = rowsToConfirm.length;
                confirmDialogValueNotConfirmable = data.length;
            }
            confirmDialogValueConfirmable = rowsToConfirm.length;
            confirmDialogData = rowsToConfirm;
            confirmDialogOpen = true;
        } else {
            // No rows can be confirmed
            $snackbarWarning.text = $_("no_rows_can_be_confirmed");
            $snackbarWarning.element && $snackbarWarning.element.open();
        }
    }

    async function confirmPurchase(data) {
        console.log("================= confirmPurchase ==============");
        console.log({ data });
        let promises = [];
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("updating_please_wait");
        [...data].forEach((r) => {
            promises.push(
                apiConfirmForecastPurchase({
                    forecastId: r.id,
                    status: "confirmed",
                }),
            );
        });
        Promise.all(promises)
            .then((responses) => {
                [...responses].forEach((r) => {
                    console.log({ r });
                    var row = bsItems.filter((i) => i.id == r[0].id)[0];
                    row.status = r[0].status;
                    row.purchaseConfirmed = r[0].purchaseConfirmed;
                    products.forEach((p) => {
                        if (p.productId == row.productId) {
                            p.forecasts.set(
                                row.id,
                                row.purchaseConfirmed == "Y",
                            );
                            p.resolved = ![...p.forecasts.values()].includes(
                                false,
                            );
                        }
                    });
                });

                bsItems = bsItems;

                products = products;

                apiCallProcessing = false;
                apiCallProcessingMessage = $_("");
                $snackbarSuccess.text = $_("purchases_confirmed");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                apiCallProcessing = false;
                apiCallProcessingMessage = $_("");
                if (
                    !handleApiError(
                        $_("confirm_purchase_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    }

    const batchAutobuy = (data) => {
        console.log("================= batchAutobuy ==============");
        console.log({ data });
        let rows = [...data]
            .filter(
                (r) =>
                    r.autobuy &&
                    r.purchasedVol == 0 &&
                    ["new", "updated"].includes(r.status) &&
                    (r.canBuy == "Y" || $authenticatedUser.isAdmin),
            )
            .map((r) => {
                return {
                    year: r.year,
                    week: r.week,
                    country: r.country,
                    forecastId: r.id,
                };
            });

        if (rows.length > 0) {
            apiCallProcessing = true;
            apiCallProcessingMessage = $_("processing_autobuy_please_wait");
            apiPurchaseAutobuy(rows)
                .then((responses) => {
                    console.log({ responses });
                    if (responses && responses.length > 0) {
                        let fcOK = responses
                            .filter((x) => x.result == "OK")
                            .map((x) => x.forecastId);
                        let fcErr = responses
                            .filter((x) => x.result == "ERROR")
                            .map((x) => x.forecastId);
                        bsItems.forEach((x) => {
                            if (fcOK.includes(x.id)) {
                                x.status = "purchased";
                                x.autobuyStatus = "OK";
                                x.purchasedVol = x.totalVol;
                            }
                            if (fcErr.includes(x.id)) {
                                x.autobuyStatus = "ERROR";
                                x.autobuyDetail = responses
                                    .filter((r) => r.forecastId == x.id)
                                    .at(0)?.detail;
                            }
                        });
                        bsItems = bsItems;
                        if (fcOK.length > 0) {
                            $snackbarSuccess.text = $_("autobuy_finished");
                            $snackbarSuccess.element &&
                                $snackbarSuccess.element.open();
                        } else {
                            $snackbarWarning.text = $_(
                                "autobuy_finished_nothing_bought",
                            );
                            $snackbarWarning.element &&
                                $snackbarWarning.element.open();
                        }
                    }
                    apiCallProcessing = false;
                    apiCallProcessingMessage = $_("");
                })
                .catch((error) => {
                    apiCallProcessingMessage = $_("");
                    if (
                        !handleApiError(
                            $_("autobuy_failed", {
                                values: { reason: error.message },
                            }),
                            error,
                            "non-fatal",
                        )
                    )
                        throw error;
                });
        } else {
            $snackbarWarning.text = $_("found_no_suitable_rows_for_autobuy");
            $snackbarWarning.element && $snackbarWarning.element.open();
        }
    };

    function batchReject(data) {
        console.log("================= batchReject ==============");
        console.log({ data });
        var rejectRows = [...data].filter(
            (r) =>
                r.purchasedVol == 0 &&
                ["new", "updated"].includes(r.status) &&
                (r.canBuy == "Y" || $authenticatedUser.isAdmin),
        );
        if (rejectRows.length > 0) {
            showEditStatusPopup(
                "reject",
                "batch",
                rejectRows,
                "reject",
                [...forecastStatusesArray].filter((s) => s.id == "rejected"),
                "rejected",
                null,
                null,
            );
        } else {
            $snackbarWarning.text = $_("found_no_suitable_rows_for_reject");
            $snackbarWarning.element && $snackbarWarning.element.open();
        }
    }

    const updateBSRow = (data, status, reasonCode, comment) => {
        console.log("================= updateBSRow ==============");
        console.log({ data });
        console.log({ status });
        console.log({ reasonCode });
        console.log({ comment });
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("updating_please_wait");
        data.status = status;
        data.reasonCode = reasonCode;
        data.userComment = comment;
        data.autobuy = data.autobuy ? 1 : 0;
        apiUpdateForecast(data)
            .then((res) => {
                console.log({ res });
                var row = bsItems.filter((i) => i.id == data.id)[0];
                row.purchaseConfirmed =
                    row.status == "rejected" ? "Y" : row.purchaseConfirmed;
                row = data;
                console.log({ row });
                bsItems = bsItems;
                products.forEach((p) => {
                    if (p.productId == row.productId) {
                        p.forecasts.set(row.id, row.purchaseConfirmed == "Y");
                        p.resolved = ![...p.forecasts.values()].includes(false);
                    }
                });
                products = products;
                $snackbarSuccess.text = $_("forecast_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
                fcDialogOpen = false;
                apiCallProcessing = false;
                apiCallProcessingMessage = $_("");
            })
            .catch((error) => {
                console.log(error);
                apiCallProcessing = false;
                apiCallProcessingMessage = $_("");
                if (
                    !handleApiError(
                        $_("update_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    };

    const batchUpdateBSRows = (data, status, reasonCode, comment) => {
        console.log("================= batchUpdateBSRows ==============");
        console.log({ data });
        console.log({ status });
        console.log({ reasonCode });
        console.log({ comment });
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("updating_please_wait");
        let promises = [];
        for (let d of [...data]) {
            d.status = status;
            d.reasonCode = reasonCode;
            d.userComment = comment;
            d.autobuy = d.autobuy ? 1 : 0;
            promises.push(apiUpdateForecast(d));
        }
        Promise.all(promises)
            .then((responses) => {
                console.log({ bsItems });
                console.log({ responses });
                [...responses].forEach((r) => {
                    var row = bsItems.filter((i) => i.id == r.id)[0];
                    //row = r;
                    row.purchaseConfirmed =
                        row.status == "rejected" ? "Y" : row.purchaseConfirmed;
                    console.log({ row });
                    products.forEach((p) => {
                        if (p.productId == row.productId) {
                            p.forecasts.set(
                                row.id,
                                row.purchaseConfirmed == "Y",
                            );
                            p.resolved = ![...p.forecasts.values()].includes(
                                false,
                            );
                        }
                    });
                });
                bsItems = bsItems;
                products = products;

                apiCallProcessing = false;
                apiCallProcessingMessage = $_("");
                $snackbarSuccess.text = $_("forecasts_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                apiCallProcessing = false;
                apiCallProcessingMessage = $_("");
                if (
                    !handleApiError(
                        $_("update_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    };

    const updateAutobuy = (id, autobuy) => {
        console.log("================= updateAutobuy ==============");
        console.log({ id });
        console.log({ autobuy });
        autobuy = autobuy ? 1 : 0;
        apiUpdateProductDetail({ id: id, autobuy: autobuy })
            .then((res) => {
                console.log({ res });
                $snackbarSuccess.text = $_("product_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
                fcDialogOpen = false;
            })
            .catch((error) => {
                console.log(error);
                if (
                    !handleApiError(
                        $_("product_update_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    };

    const weekDataInvalidated = () => {
        console.log("================= weekDataInvalidated ==============");
        dataLoaded = false;
        bsItems = [];
        updatePrivileges();
        $buyingScreenState.currentPage = 0;
    };

    const updatePrivileges = () => {
        console.log("================= updatePrivileges ==============");
        if ($selectedWeek && $selectedWeek.status) {
            canInsert = isActionEnabled(
                "buying",
                "insert",
                $selectedWeek.status,
            );
            canUpdate = isActionEnabled("buying", "edit", $selectedWeek.status);
            canDelete = isActionEnabled(
                "buying",
                "delete",
                $selectedWeek.status,
            );
        } else {
            canInsert = canUpdate = canDelete = false;
        }
    };

    onMount(() => {
        console.log("================= onMount ==============");
        // console.log({selectedDC}, {dcSearchArray}, {$bsDcList}, $bsDcList.length);
        if (selectedDC && $bsDcList.length > 0) {
            dcSearchArray = $bsDcList.filter((dc) =>
                selectedDC.map((x) => x.id).includes(dc.id),
            );
        }
        updatePrivileges();
        getUserList("Buyer");
    });

    const getUserList = (role) => {
        console.log("================= getUserList ==============");
        getUserManagement(null, role)
            .then((res) => {
                buyerList = res
                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                    .map((x) => {
                        return {
                            id: x.email,
                            label: x.lastName + " " + x.firstName,
                            uuid: x.uuid,
                        };
                    });
                console.log({ buyerList });
                if ($authenticatedUser.isBuyer) {
                    buyerSearchArray.push(
                        [...buyerList]
                            .filter((l) => l.uuid == $authenticatedUser.uuid)
                            .at(0),
                    );
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                buyerList = [];
                if (
                    !handleApiError(
                        $_("load_list_of_buyers_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    };

    async function changeWeekStatus(e) {
        console.log("================= changeWeekStatus ==============");
        try {
            const res = await apiGetForecast(
                null,
                $selectedWeek.tesco_year,
                $selectedWeek.tesco_week,
                null /*dcSearch.map(x => {return {id: x.id}.id}).join(',').toUpperCase()*/,
                null /*warehouseId*/,
                null /*productId*/,
                null /*productDetailId*/,
                null /*productDesc*/,
                null /*tpnb*/,
                null /*description*/,
                null /*ean*/,
                null /*buyer*/,
                null /*technicalManager*/,
                null /*note*/,
                null /*status*/,
                null /*reasonCode*/,
                null /*fileName*/,
                "N" /*purchaseConfirmed*/,
            );
            console.log({ res });
            if (res.length > 0) {
                let buyers = [...new Set([...res].map((x) => x.buyer))];
                console.log({ buyers });
                $snackbarError.text = $_(
                    "cannot_close_buying_because_uncompleted_purchases",
                    { values: { buyers: buyers.length } },
                );
                $snackbarError.element && $snackbarError.element.open();
            } else {
                // Open confrmation for Week Status change
                confirmChangeWeekNotifyUsers = true;
                changeWeekStatusDialogOpen = true;
            }
        } catch (e) {
            console.log("changeWeekStatus().error");
            console.log(e);
            $apiError = true;
            $apiErrorDetails = { loc: "changeWeekStatus()", err: e };
        }
    }

    async function updateWeekStatus(notifyUsersFlag) {
        console.log("================= updateWeekStatus ==============");
        let newWeekStatus = "purchased";
        apiUpdateWeekStatus({ id: $selectedWeek.id, status: newWeekStatus })
            .then((res) => {
                //console.log({ res });
                if (enableNotifications && notifyUsersFlag) {
                    apiCreateNotificationJob(
                        $selectedWeek.tesco_year,
                        $selectedWeek.tesco_week,
                        "PURCHASED",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    );
                }
                $snackbarSuccess.text = $_("week_status_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
                $selectedWeek.status = newWeekStatus;
                canUpdate = false;
            })
            .catch((error) => {
                //console.log(error);
                if (
                    !handleApiError(
                        $_("week_status_update_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    }

    async function exportToExcel(tableData) {
        let data = [];
        let fileName =
            "Export_Buying_List_" +
            $selectedWeek.tesco_year +
            "-" +
            $selectedWeek.tesco_week;
        let reportName = $_("buying_list_report");
        let headersTranslations = [
            $_("year"),
            $_("week"),
            $_("tpn_en"),
            $_("product_en"),
            $_("dc"),
            $_("buyer"),
            $_("tpnb"),
            $_("tpnb_desc"),
            $_("units"),
            $_("forecast_vol"),
            $_("purchased_vol"),
            $_("status"),
            $_("purchase_confirmed"),
            $_("reason_code"),
            $_("buyer_comment"),
            $_("autobuy"),
        ];
        let columnTypes = [
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "number",
            "number",
            "text",
            "text",
            "text",
            "text",
            "text",
        ];

        tableData.forEach(function (row) {
            let rowData = [];
            rowData.push(Number(row.year));
            rowData.push(Number(row.week));
            rowData.push(row.productId);
            rowData.push(row.descriptionEn);
            rowData.push(
                [...$dcList].filter((x) => x.id == row.warehouseId)[0].code,
            );
            rowData.push(row.buyer);
            rowData.push(row.tpnb);
            rowData.push(row.description);
            rowData.push($_(row.units));
            rowData.push(Number(row.totalVol));
            rowData.push(Number(row.purchasedVol));
            rowData.push($_(row.status));
            rowData.push($_(row.purchaseConfirmed));
            rowData.push($_(row.reasonCode));
            rowData.push(row.userComment);
            rowData.push($_(row.autobuy ? "yes" : "no"));

            // rowData.push(new Date(row.endDate).toAppDate());

            data.push(rowData);
        });

        let exportData = {
            fileName: fileName,
            reportName: reportName,
            headersTranslations: headersTranslations,
            columnTypes: columnTypes,
            data: data,
        };

        apiGeneralExportToExcel(exportData)
            .then((res) => {
                $snackbarSuccess.text = $_("export_buying_succeeded");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (
                    !handleApiError(
                        $_("export_buying_list_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    }

    // $: {
    //    console.log("==================");
    //    console.log({selectedDC});
    //    console.log({$selectedWeek});
    // }
</script>

<!--<div class="pt-2">
    <ModuleHeader/>
</div>-->

<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />

{#if moduleToShow == "purchase_screen"}
    <PurchaseScreen
        bind:selectedProduct
        preselectedDCs={[...dcSearchArray.map((x) => x.id)]}
        {canInsert}
        {canUpdate}
        {canDelete}
        products={filteredProducts}
        on:goBack={handleBuyingScreenEvents}
        on:confirmItem={handleBuyingScreenEvents}
        on:updateForecastStatus={handleBuyingScreenEvents}
        on:weekStatusChanged={updatePrivileges}
    />
{:else}
    <section class="main-data-view">
        <section class="w-100 header top-splitter">
            <Flex
                direction="row"
                class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1"
                justify="between"
            >
                <WeekInfo
                    on:weekChanged={weekDataInvalidated}
                    on:weekStatusChanged={updatePrivileges}
                />
                <Flex direction="row" justify="between" class="gap-1 w-100">
                    <GetDataButton
                        type="BS"
                        approvalNeeded={false}
                        approvalMessage=""
                        on:get={loadBSData}
                    />
                    <Flex direction="row" justify="end" class="w-100 gap-1">
                        <ExportToExcelButton
                            disabled={filteredItems.length == 0}
                            on:export={() => {
                                exportToExcel(
                                    selectedItems.length > 0
                                        ? selectedItems
                                        : filteredItems,
                                );
                            }}
                        />
                        {#if $authenticatedUser.isAdmin}
                            <ChangeWeekStatusButton
                                approvalNeeded={false}
                                approvalMessage={null}
                                showNotifyFlag={false}
                                title={"stop_buying"}
                                disabled={$selectedWeek &&
                                    $selectedWeek.status &&
                                    $selectedWeek.status !== "forecasted"}
                                on:changeStatus={changeWeekStatus}
                            />
                        {/if}
                        <Button
                            class="{!canUpdate || filteredItems.length == 0
                                ? 'grey-text white-grey-outlined'
                                : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
                            title={$_("autobuy")}
                            disabled={!canUpdate || filteredItems.length == 0}
                            on:click={() => {
                                performAction("autobuy");
                            }}
                        >
                            <Icon
                                tag="svg"
                                viewBox="0 0 24 24"
                                class="pr-0 pl-1"
                            >
                                <path
                                    fill="currentColor"
                                    d={mdiCartArrowDown}
                                />
                            </Icon>
                            {$_("autobuy")}
                        </Button>
                        <Button
                            class="{!canUpdate || filteredItems.length == 0
                                ? 'grey-text white-grey-outlined'
                                : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
                            title={$_("reject")}
                            disabled={!canUpdate || filteredItems.length == 0}
                            on:click={() => {
                                performAction("reject");
                            }}
                        >
                            <Icon
                                tag="svg"
                                viewBox="0 0 24 24"
                                class="pr-0 pl-1"
                            >
                                <path fill="currentColor" d={mdiCancel} />
                            </Icon>
                            {$_("reject")}
                        </Button>
                        <Button
                            class="{!canUpdate || filteredItems.length == 0
                                ? 'grey-text white-grey-outlined'
                                : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
                            title={$_("confirm_purchase")}
                            disabled={!canUpdate || filteredItems.length == 0}
                            on:click={() => {
                                performAction("purchase");
                            }}
                        >
                            <Icon
                                tag="svg"
                                viewBox="0 0 24 24"
                                class="pr-0 pl-1"
                            >
                                <path fill="currentColor" d={mdiCheck} />
                            </Icon>
                            {$_("confirm")}
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </section>

        <section id="sec" class="filters tescoblue-border-top-thin">
            <Paper
                id="paper"
                class="grey lighten-3 w-100 bs-bb pt-0 pb-1 mt-0 mb-0"
            >
                <div id="div" class="smui-paper__content bs-bb">
                    <section class="mt-2 pt-0 w-100">
                        <BSFilterBar
                            bind:ceTpnSearch
                            bind:ceDescSearch
                            bind:tpnbSearch
                            bind:tpnbDescSearch
                            bind:selectedDC={dcSearchArray}
                            bind:statusSearchArray
                            bind:fileNameSearch
                            bind:buyerSearchArray
                            bind:confirmedSearchArray
                            bind:autobuySearchArray
                            bind:reasonSearchArray
                            {buyerList}
                        />
                    </section>
                </div>
            </Paper>
        </section>

        <section id="sec" class="data tescoblue-border-top-thin">
            <Paper id="paper" class="grey lighten-3 w-100 bs-bb pt-3">
                <div
                    id="div"
                    class="smui-paper__content bs-bb"
                    style="overflow-x: clip;"
                >
                    <BSDataTable
                        items={bsItems}
                        {tpnbSearch}
                        {dataLoaded}
                        {loadingData}
                        {ceTpnSearch}
                        {tpnbDescSearch}
                        {ceDescSearch}
                        {fileNameSearch}
                        {buyerSearchArray}
                        selectedDC={dcSearchArray.map((x) => x.id)}
                        statusSearchArray={statusSearchArray.map((x) => x.id)}
                        {confirmedSearchArray}
                        {autobuySearchArray}
                        {reasonSearchArray}
                        {canUpdate}
                        {canInsert}
                        bind:selectedItems
                        bind:filteredItems
                        on:purchaseItem={handleBuyingScreenEvents}
                        on:buyingScreenBatchEditStatusEdit={handleBuyingScreenEvents}
                        on:buyingScreenRowEditStatusEdit={handleBuyingScreenEvents}
                        on:buyingScreenChangeAutobuyStatus={handleBuyingScreenEvents}
                    />
                    <!--        <BSDataTable/>-->
                </div>
            </Paper>
        </section>
    </section>
{/if}

<BSEditStatusPopup
    data={popupData}
    open={popupOpen}
    type={popupType}
    action={popupAction}
    availableStatuses={popupAvailableStatuses}
    selectedStatus={popupStatus}
    bind:selectedReason={popupReasonCode}
    bind:comment={popupComment}
    title={$_(popupTitle)}
    on:popupCancel={handleBuyingScreenEvents}
    on:popupSave={handleBuyingScreenEvents}
></BSEditStatusPopup>

<ConfirmationDialog
    open={confirmationOpen}
    title={$_("no_rows_are_selected")}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={true}
    headerColors="tescoblue white-text"
    on:reject={() => {
        confirmationOpen = false;
    }}
    on:confirm={() => {
        confirmationOpen = false;
        batchActionConfirmed(confirmationAction);
    }}
>
    {$_(confirmationText, { values: { action: confirmationAction } })}
</ConfirmationDialog>

<ConfirmationDialog
    open={changeWeekStatusDialogOpen}
    title={$_("close_buying")}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        changeWeekStatusDialogOpen = false;
    }}
    on:confirm={() => {
        changeWeekStatusDialogOpen = false;
        updateWeekStatus(confirmChangeWeekNotifyUsers);
    }}
>
    <Flex direction="column">
        <div class="tescoblue-text fs-1p2rem">
            {$_("do_you_want_to_close_buying_for_this_week")}
        </div>
        {#if enableNotifications}
            <FormField style="align-self: start;">
                <Checkbox bind:checked={confirmChangeWeekNotifyUsers} />
                <span slot="label">{$_("notify_users")}</span>
            </FormField>
        {/if}
    </Flex>
</ConfirmationDialog>

<ConfirmationDialog
    open={confirmDialogOpen}
    title={$_(confirmDialogTitle)}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={true}
    headerColors="tescoblue white-text"
    on:reject={() => {
        confirmDialogOpen = false;
    }}
    on:confirm={() => {
        confirmDialogOpen = false;
        confirmPurchase(confirmDialogData);
    }}
>
    {$_(confirmDialogText, {
        values: {
            confirmable: confirmDialogValueConfirmable,
            notConfirmable: confirmDialogValueNotConfirmable,
        },
    })}
</ConfirmationDialog>

<style>
</style>
