<script lang="ts">
  import { afterUpdate } from "svelte";

  import DataTable, { Head, Body, Row, Cell, Pagination, Label, SortValue } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import IconButton, { Icon } from "@smui/icon-button";
  import Checkbox from "@smui/checkbox";
  import Switch from "@smui/switch";

  import CircularProgress from "@smui/circular-progress";

  import { mdiPencilBoxOutline, mdiCartOutline, mdiDelete, mdiAlert, mdiAlertCircle, mdiEye, mdiCheckCircleOutline } from "@mdi/js";
  import Flex from "svelte-flex";
  import { _ } from "svelte-i18n";

  import type { BuyingListItems } from "../../../types/BuyingList";
  import { dcList } from "../../../stores/AppConfig";
  import { authenticatedUser, buyingScreenState } from "../../../stores/AppStatus";
  import { createEventDispatcher } from "svelte";
  import { nvl } from "../Upload/Upload.js";

  // Items
  export let items: BuyingListItems[] = [];
  export let filteredItems: BuyingListItems[] = [];

  // Filters
  export let selectedDC = [];
  export let tpnbSearch = "";
  export let ceTpnSearch = "";
  export let tpnbDescSearch = "";
  export let ceDescSearch = "";
  export let fileNameSearch = "";
  export let statusSearchArray = [];
  export let buyerSearchArray = [];
  export let selectedItems = [];
  export let canUpdate = false;
  export let canInsert = false;
  export let confirmedSearchArray = [];
  export let autobuySearchArray = [];
  export let reasonSearchArray = [];

  // Data Status
  export let dataLoaded = false;
  export let loadingData = false;

  const dispatch = createEventDispatcher();
  let checkedAll = false;

  //let selected: BuyingListItems[] = [];

  // Pagging
  let rowsPerPage = $buyingScreenState?.rowsPerPage || 10;
  let currentPage = $buyingScreenState?.currentPage || 0;

  // Filter
  $: filteredItems = items?.filter((item) => {
    const ceTpnMatch = item.productId?.toString().includes(ceTpnSearch);
    const tpnbMatch = item.tpnb?.toString().includes(tpnbSearch);
    const productNameEnMatch = (item.descriptionEn ?? "").toLowerCase().includes(ceDescSearch?.toLowerCase() ?? "");
    const tpnbDescMatch = (item.description ?? "").toLowerCase().includes(tpnbDescSearch.toLowerCase());
    const fileNameMatch = (item.fileName ?? "").toLowerCase().includes(fileNameSearch.toLowerCase());

    // arrays
    const statusMatch = statusSearchArray.length > 0 ? statusSearchArray.some((unitId) => item.status?.includes(unitId)) : true;
    const reasonMatch = reasonSearchArray.length > 0 ? reasonSearchArray.some((unitId) => item.reasonCode?.includes(unitId.id)) : true;
    const confirmedMatch =
      confirmedSearchArray.length > 0 ? confirmedSearchArray.map((x) => x.id).some((unitId) => item.purchaseConfirmed?.includes(unitId)) : true;
    const autobuyMatch =
      autobuySearchArray.length > 0
        ? autobuySearchArray
            .map((x) => x.id)
            .some((autobuyOption) => (autobuyOption == "Y" && item.autobuy) || (autobuyOption == "N" && !item.autobuy))
        : true;

    // Convert item.warehouseId to a DC code for comparison
    const itemDcCode = findDcCodeById(item.warehouseId);
    const dcMatch = selectedDC.length > 0 ? selectedDC.includes(itemDcCode) : true;
    const buyerMatch =
      buyerSearchArray.length > 0
        ? buyerSearchArray
            .map((x) => {
              return x.id;
            })
            .includes(item.buyer)
        : true;

    return (
      ceTpnMatch &&
      productNameEnMatch &&
      tpnbMatch &&
      tpnbDescMatch &&
      statusMatch &&
      dcMatch &&
      fileNameMatch &&
      buyerMatch &&
      confirmedMatch &&
      autobuyMatch &&
      reasonMatch
    );
  });

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, filteredItems.length);
  $: slice = filteredItems.slice(start, end);
  $: lastPage = Math.max(Math.ceil(filteredItems.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  $: {
    selectedItems = [...filteredItems].filter((i) => i.selected);
    //console.log({selectedItems});
  }

  $: editable = (canInsert || canUpdate) && filteredItems.length > 0;

  // Function to find DC code by ID
  function findDcCodeById(dcId) {
    const dc = $dcList.find((d) => d.id === dcId);
    return dc ? dc.code : "Unknown";
  }

  // Table Head
  $: tableHead = [
    { columnId: "tpnEn", label: "tpn_en_tpnb", numeric: false, extraClasses: "" }, 
    { columnId: "productNameEn", label: "product_en_tpnb", numeric: false, extraClasses: "" },
    { columnId: "dc", label: "dc", numeric: false, extraClasses: "" },
    { columnId: "buerEmail", label: "buyer", numeric: false, extraClasses: "" },
    { columnId: "units", label: "units", numeric: false, extraClasses: "" },
    { columnId: "forecastPurchasedVol", label: "forecast_purchased_vol", numeric: true, extraClasses: " wrap-spaces text-center " },
    { columnId: "status", label: "status", numeric: false, extraClasses: "" },
    { columnId: "purchaseConfirmed", label: "purchase_confirmed", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "reason", label: "reason_code", numeric: false, extraClasses: " wrap-spaces " },
    { columnId: "buyerComment", label: "buyer_comment", numeric: false, extraClasses: " wrap-spaces " },
    { columnId: "autobuy", label: $_("autobuy"), numeric: false, extraClasses: "" },
  ];

  function checkUncheckAll() {
    console.log("================= checkUncheckAll ==============");
    console.log(checkedAll);
    filteredItems.forEach((i) => {
      i.selected = !checkedAll;
    });
    selectedItems = [...filteredItems].filter((i) => i.selected);
  }

  afterUpdate(() => {
    if ($buyingScreenState?.scrollPosition >= 0) {
      console.log("SCROLL");
      document.querySelector("#tab-content").scrollTop = $buyingScreenState.scrollPosition;
      $buyingScreenState.scrollPosition = -1;
    }
  });

  $: if (currentPage || rowsPerPage) {
    $buyingScreenState.currentPage = currentPage;
    $buyingScreenState.rowsPerPage = rowsPerPage;
  }
</script>

<DataTable table$aria-label="Buying List Table" style="width: 100%;" class="w-100 mt-2 report">
  <Head>
    <Row>
      {#if editable}
        <Cell columnId="checkbox" class="datatable-header pl-0 pr-0" checkbox>
          <Checkbox bind:checked={checkedAll} on:click={checkUncheckAll} />
        </Cell>
      {/if}
      <Cell columnId="actions" class="datatable-header pl-0 pr-0 bs {editable ? 'left-1col-rw' : ''} right-splitter">
        {#if editable}
          <!--
          <div title="{$_('batch_edit')}">
            <IconButton
                size="button"
                class="tescoblue-text opacity-1"
                on:click={() => {console.log({slice});
                                  dispatch('buyingScreenBatchEditStatusEdit')}}
            >
                <Icon tag='svg' viewBox="0 0 24 24" class="tescoblue white-text">
                    <path fill="currentColor" d={mdiPencilBoxOutline} />
                </Icon>
            </IconButton>
          </div>
        -->
        {/if}
      </Cell>
      {#each tableHead as head (head.columnId)}
        <Cell numeric={head.numeric} columnId={head.columnId} class="datatable-header right-splitter {head.extraClasses}">
          <Label>{$_(head.label)}</Label>
        </Cell>
      {/each}
    </Row>
  </Head>
  <Body>
    {#if loadingData}
      <Row>
        <td colspan="16" class="mdc-data-table__cell" style="width: 100%; text-align: center">
          <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
        </td>
      </Row>
    {:else if slice.length == 0}
      {#if !dataLoaded}
        <Row>
          <td colspan="16" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("load_week_data")}</td>
        </Row>
      {:else}
        <Row>
          <td colspan="16" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("no_data_for_week")}</td>
        </Row>
      {/if}
    {:else}
      {#each slice as item (item.id)}
        <Row class={item.id == $buyingScreenState?.lastBuyedRow ? "last-purchased" : ""}>
          {#if editable}
            <Cell class="pl-0 pr-0" checkbox>
              <Checkbox bind:checked={item.selected} />
            </Cell>
          {/if}
          <Cell class="pl-0 pr-0 right-splitter-sub">
            <Flex direction="row" justify="start">
              {#if canUpdate && (item.canBuy == "Y" || $authenticatedUser.isAdmin)}
                <div title={$_("edit")}>
                  <IconButton size="button" class="tescoblue-text opacity-1" on:click={() => dispatch("buyingScreenRowEditStatusEdit", item)}>
                    <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                      <path fill="currentColor" d={mdiPencilBoxOutline} />
                    </Icon>
                  </IconButton>
                </div>
              {/if}
              <div title={canInsert || canUpdate ? $_("buy") : $_("view_purchases")}>
                <IconButton size="button" class="tescoblue-text opacity-1" on:click={() => dispatch("purchaseItem", item)}>
                  <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                    {#if (canInsert || canUpdate) && (item.canBuy == "Y" || $authenticatedUser.isAdmin)}
                      <path fill="currentColor" d={mdiCartOutline} />
                    {:else}
                      <path fill="currentColor" d={mdiEye} />
                    {/if}
                  </Icon>
                </IconButton>
              </div>
              {#if item?.autobuyStatus}
                <div
                  title={item?.autobuyStatus == "OK"
                    ? $_("autobuy_ok")
                    : $_("autobuy_error", { values: { message: $_(item.autobuyDetail?.toLocaleLowerCase()) } })}
                >
                  <IconButton size="button" class="opacity-1" disabled>
                    <Icon tag="svg" viewBox="0 0 24 24" class={item?.autobuyStatus == "OK" ? "green-text" : "tescored-text"}>
                      <path fill="currentColor" d={item?.autobuyStatus == "OK" ? mdiCheckCircleOutline : mdiAlertCircle} />
                    </Icon>
                  </IconButton>
                </div>
              {/if}
            </Flex>
          </Cell>
          <Cell class="right-splitter-sub"
            >{item.productId ?? ""}<br />{item.tpnb ?? ""}</Cell
          >
          <Cell class="right-splitter-sub">{item.descriptionEn ?? ""}<br />{item.description ?? ""}</Cell>
          <Cell class="right-splitter-sub">{item.warehouseCode}</Cell>
          <Cell class="right-splitter-sub">{item.buyer}</Cell>
          <Cell class="right-splitter-sub">{$_(nvl(item.units, ""))}</Cell>
          <Cell class="right-splitter-sub" numeric>{item.totalVol + item.forecastAmendVol} / {item.purchasedVol + item.purchasedAmendVol}</Cell>
          <Cell class="right-splitter-sub">{$_(nvl(item.status, ""))}</Cell>
          <Cell class="right-splitter-sub text-center">{$_(nvl(item.purchaseConfirmed, ""))}</Cell>
          <Cell class="right-splitter-sub">{$_(nvl(item.reasonCode, ""))}</Cell>
          <Cell class="right-splitter-sub">{item.userComment ?? ""}</Cell>
          <Cell class="text-center">
            <Switch
              disabled={item.canBuy != "Y" && !$authenticatedUser.isAdmin}
              bind:checked={item.autobuy}
              on:click={() => {
                dispatch("buyingScreenChangeAutobuyStatus", item);
              }}
            />
          </Cell>
        </Row>
      {/each}
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {filteredItems.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}
      >first_page</IconButton
    >
    <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}
      >chevron_left</IconButton
    >
    <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}
      >chevron_right</IconButton
    >
    <IconButton
      class="material-icons"
      action="last-page"
      title="Last page"
      on:click={() => (currentPage = lastPage)}
      disabled={currentPage === lastPage}>last_page</IconButton
    >
  </Pagination>
</DataTable>

<style>
</style>
