<script>
    import { onMount } from "svelte";
    import { get as getValue } from "svelte/store";
    import { push } from "svelte-spa-router";
    import { isLoading } from "svelte-i18n";

    import { getUser, userTokenRefresh, getUserManagement } from "../api/User";
    import { getWeeks, getWeekDashboard } from "../api/Weeks";
    import { getDCList, getListData } from "../api/LOV";
    import { getProductHierarchy } from "../api/Product";
    import { getExchangeRates } from "../api/ExchangeRate";
    import { getAppParameters } from "../api/Parameters";
    import { getUserSuppliers } from "../api/Supplier";

    import { handleApiError } from "../components/modules/lib/errorHandler";

    import { wsConnect } from "../stores/WebsocketStore";

    import {buyersList} from "../stores/Buyers";

    import {
        unauthorized,
        authenticatedUser,
        sessionExpired,
        userLoggedOut,
        loginAttemptCount,
        appInitFailed,
        appInitFailedDetails,
        selectedYear,
        selectedWeek,
        exchangeRates,
        userLanguage,
    } from "../stores/AppStatus";

    import {
        maxLoginAttempts,
        toolkitEndPointUrl,
        dcList,
        fullDcList,
        unitList,
        countryList,
        productHierarchyList,
        temperatureList,
        currencyList,
        roles,
        modules,
        dashboardList,
        appParameters
    } from "../stores/AppConfig";
    import { years, weeks } from "../stores/Weeks";

    import Paper, { Content } from "@smui/paper";
    import Button from "@smui/button";

    import { Icon } from "@smui/icon-button";
    import { mdiLogin } from "@mdi/js";

    import TescoAppLogo from "../components/elementary/TescoAppLogo.svelte";
    import CustomCard from "../components/elementary/CustomCard.svelte";
    import Loader from "../components/elementary/Loader.svelte";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    let loading = false;
    let loginMsg = "";

    onMount(() => {
        if ($userLoggedOut) {
            console.log("Logged out!");
            return;
        }

        if ($unauthorized && !$sessionExpired && $loginAttemptCount <= maxLoginAttempts) {
            $loginAttemptCount = Number($loginAttemptCount) + 1;
            login();
        }
    });

    const login = async () => {
        loading = true;

        try {
            const response = await getUser();
            console.log("getUser(): ", { response });

            if (response && Array.isArray(response)) {
                console.log("Token is valid...");
                $loginAttemptCount = 0;

                // Parse and normalize the authenticated user data
                const user = {
                    ...response[0],
                    tokenExpirationDate: new Date(response[0]?.tokenExpirationDate),
                    role: String(response[0]?.role).toLowerCase(),
                };

                // Pre-fill error dialog
                document.getElementById('errorModalBodyUserName').innerText = [user.name, user.uuid].join(" - ");
                document.getElementById('errorModalBodyUserRole').innerText = user.role;

                // Automatically switch language based on user data
                if (user.language != $userLanguage) {
                    userLanguage.set(user.language);
                }

                if (user.permissions && user.permissions.length > 0) {
                    $authenticatedUser = user;

                    console.log({ $authenticatedUser });
                    $unauthorized = false;

                    // Map user permissions to modules
                    const userModules = user.permissions.map((perm) => ({
                        id: perm.moduleCode,
                        name: perm.moduleName || perm.moduleCode,
                        labelType: perm.labelType || "text",
                        icon: perm.icon || "",
                        tabs: Array.isArray(perm.tabs) ? perm.tabs : typeof perm.tabs === "string" ? perm.tabs.split(",") : [],
                        insertStatuses: perm.insertStatuses ? perm.insertStatuses.split(",") : [],
                        editStatuses: perm.editStatuses ? perm.editStatuses.split(",") : [],
                        deleteStatuses: perm.deleteStatuses ? perm.deleteStatuses.split(",") : [],
                    }));

                    // Set the modules store with the users modules
                    modules.set(userModules);

                    // Populate the roles store with the current role
                    roles.set([
                        {
                            id: $authenticatedUser.role,
                            name: $authenticatedUser.role.charAt(0).toUpperCase() + $authenticatedUser.role.slice(1),
                            modules: userModules.map((mod) => mod.id),
                            readonly: $authenticatedUser.role === "readonly",
                        },
                    ]);

                    //console.log("$roles: ", $roles);
                    //console.log("$modules: ", $modules);

                    // Update role-based flags
                    $authenticatedUser.isAdmin = $authenticatedUser.role === "admin";
                    $authenticatedUser.isBuyer = $authenticatedUser.role === "buyer";
                    $authenticatedUser.isSupplier = $authenticatedUser.role === "supplier";
                    $authenticatedUser.isSc = $authenticatedUser.role === "supplychain";
                    $authenticatedUser.isReadOnly = $authenticatedUser.role === "readonly";

                    if ($authenticatedUser.isSupplier) {
                        try {
                            $authenticatedUser.supplierList = await getUserSuppliers($authenticatedUser.uuid);
                        } catch (error) {
                            console.log("getUserSuppliers().error");
                            console.log(error);
                            if (!handleApiError($_("unexpected_error_in", { values: { step: "getUserSuppliers()" } }), error, 'fatal')) throw(error);
                        }
                    }

                    // Initialize the application
                    await initApp();
                    initTokenRefreshTimer(true);
                    //initWeekStatusRefreshTimer($selectedWeek?.id);

                    push("/appmenu");
                    /*
                    // For test purposes only => Select the role you want to use for FE
                    $authenticatedUser.isAdmin = false;
                    $authenticatedUser.isBuyer = false;
                    $authenticatedUser.isSupplier = true;
                    $authenticatedUser.isSc = false;
                    $authenticatedUser.isReadOnly = false;
                    // END => For test purposes only
 */
                } else {
                    loginMsg = $_("unsupported_role");
                    loading = false;
                }
            } else {
                setTimeout(() => {
                    loading = false;
                }, 1000);
                loginMsg = "Login failed!";
                loading = false;
            }
        } catch (error) {
            console.log({ error });
            if (error?.cause?.response?.status == 401) {
                console.log("Unauthorized...");
                window.location.href = $toolkitEndPointUrl + "sign-in/?url=" + window.location.origin;
            } else if (error?.cause?.response?.status == 403) {
                console.log((error.cause.response.data.payload));
                loginMsg = $_(error.cause.response.data.payload[0]);
                loading = false;
            } else {
                loginMsg = "Login failed!";
                loading = false;
            }
        }
    };

    /*** App initialization
     */
    const initApp = async () => {
        // generate list of years
        let currentYear = new Date().getFullYear();
        let minYear = 2023 > currentYear - 5 ? 2023 : currentYear - 5;
        $years = Array.from({ length: currentYear + 1 - minYear }, (_, i) => minYear + i).map((x) => {
            return { year: x, maxWeekNo: null };
        });

        let promises = [loadWeeks(), loadDCs(), loadLists(), loadProductHierarchy(), loadDashboard(), loadAppParameters(), loadBuyers()];

        await Promise.all(promises);

        wsConnect();

        $selectedWeek = $weeks.filter((x) => x.current_week).at(0);
        console.log({ $selectedWeek });
        $selectedYear = $years.filter((x) => x.year == $selectedWeek.tesco_year).at(0);

        if (!$authenticatedUser.isSupplier) {
            loadExchangeRates();
        }

        limitFutureWeeks();
        console.log({ $years });
        console.log({ $weeks });
    };

    /*** Load weeks for each year
     */
    const loadWeeks = async () => {
        $weeks = [];
        for (let year of [...$years]) {
            try {
                const res = await getWeeks(year.year);

                console.log("getWeeks in loadWeeks", { res });

                // Truncated format
                let todayStr = new Date().toISOString().split("T")[0];

                let maxWeekNo = 0;

                if (res && res.length > 0) {
                    for (let w of res) {
                        let week_start = new Date(w.weekStart + "T00:00:00Z"); // Start of the week
                        let week_end = new Date(w.weekEnd + "T23:59:59Z"); // End of the week

                        let current_week = todayStr >= w.weekStart && todayStr <= w.weekEnd;

                        let week = {
                            id: w.id,
                            tesco_week_name: w.description,
                            tesco_year: w.tescoYear,
                            tesco_week: w.tescoWeek,
                            week_start,
                            week_end,
                            year: w.calYear,
                            week_no: w.calWeek,
                            week_name: w.calYear + "W" + w.calWeek,
                            status: w.status,
                            stats: [],
                            current_week,
                        };

                        maxWeekNo = w.tescoWeek > maxWeekNo ? w.tescoWeek : maxWeekNo;
                        $weeks.push({ ...week });
                    }
                    $years.forEach((x) => {
                        if (x.year == year.year) {
                            x.maxWeekNo = maxWeekNo;
                        }
                    });
                    $years = $years;
                } else {
                    console.log(`loadWeeks(${year.year}).empty`);
                    // no weeks, remove year from list
                    $years = [...$years.filter((x) => x != year)];
                    // $appInitFailed = true;
                    // $appInitFailedDetails.push($_('no_data', {values: {type: $_('weeks')}}));
                }
            } catch (error) {
                console.log("loadWeeks().error");
                console.log(error);
                if (!handleApiError($_("unexpected_error_in", { values: { step: "loadWeeks()" } }), error, 'fatal')) throw(error);
            }
        }
    };

    const loadLists = async () => {
        try {
            // Fetch lists in parallel
            const otherListsPromises = [getListData("unitSale"), getListData("country"), getListData("temperature"), getListData("currency")];

            // Fetch the remaining lists
            const [unitSaleListData, countryListData, temperatureListData, currencyListData] = await Promise.all(otherListsPromises);

            $unitList = [...unitSaleListData];
            $countryList = [...countryListData];
            $temperatureList = [...temperatureListData];
            $currencyList = [...currencyListData];

            console.log({
                $unitList,
                $countryList,
                $temperatureList,
                $currencyList,
            });
        } catch (error) {
            console.log("loadLists().error");
            console.log(error);
            if (!handleApiError($_("unexpected_error_in", { values: { step: "loadLists()" } }), error, 'fatal')) throw(error);
        }
    };

    /*** Limit maximal future weeks to $appParameters.default_number_future_weeks
     */
    const limitFutureWeeks = () => {
        console.log("====== limitFutureWeeks() ======");
        let currentWeek = $weeks.filter((x) => x.current_week).at(0);
        let currentMaxWeekNo = $years.filter((x) => x.year == currentWeek.tesco_year).at(0).maxWeekNo;

        let newYear = currentWeek.tesco_year;
        let newMaxWeekNo = currentWeek.tesco_week + Number($appParameters?.default_number_future_weeks?.value);

        // console.log({currentWeek});
        // console.log({currentMaxWeekNo});
        // console.log({newYear});
        // console.log({newMaxWeekNo});

        if (newMaxWeekNo > currentMaxWeekNo) {
            let nextYearMaxWeekNo = $years.filter((x) => x.year == currentWeek.tesco_year + 1).at(0)?.maxWeekNo;

            if (nextYearMaxWeekNo) {
                newYear = currentWeek.tesco_year + 1;
                newMaxWeekNo = newMaxWeekNo - currentMaxWeekNo;
            } else {
                newMaxWeekNo = currentMaxWeekNo;
            }
        }

        // console.log({newYear});
        // console.log({newMaxWeekNo});

        $years.forEach((x) => {
            if (x.year == newYear) {
                x.maxWeekNo = newMaxWeekNo;
            }
        });

        $years = [...$years.filter((x) => x.year <= newYear)];
        console.log({ $years });
    };

    const loadDCs = async (country) => {
        try {
            const res = await getDCList(country);

            console.log({ res });
            let currCountry = '';

            $dcList = [];
            $fullDcList = [];
            let id = 1;
            for (let dc of res) {
                // console.log({dc});
                $dcList.push({ ...dc });
                if (currCountry != dc.country) {
                    currCountry = dc.country;
                    $fullDcList.push({
                        id: id,
                        code: currCountry,
                        country: currCountry,
                        live: true,
                        location: currCountry,
                        name: $_('all_in_country', { values: { country: currCountry } }),
                        participation: 0,
                        site: ((currCountry == 'CZ') ? 1 : (currCountry == 'SK' ? 2 : 4 )),
                        type: "COUNTRY"
                    });
                    id++;
                }
                $fullDcList.push({ ...dc, id: id});
                id++;
            }

            if ($dcList.length == 0) {
                console.log("loadDCs().empty");
                $appInitFailed = true;
                $appInitFailedDetails.push($_("no_data", { values: { type: $_("dc_list") } }));
            } else {
                // $divisionsList = [...divsArray];
                $dcList = $dcList;
                console.log({ $dcList });
                $fullDcList = $fullDcList;
                console.log({ $fullDcList });
            }
        } catch (error) {
            console.log("loadDCs().error");
            console.log(error);
            if (!handleApiError($_("unexpected_error_in", { values: { step: "loadDCs()" } }), error, 'fatal')) throw(error);
        }
    };

    const loadExchangeRates = async () => {
        try {
            let res = await getExchangeRates(null, null, null, null, true);
            // Add currency detail to each exchange rate
            res = res.map((rate) => {
                const currencyData = $currencyList.find((c) => c.id === rate.currencyId);
                return {
                    ...rate,
                    code: currencyData ? currencyData.code : "",
                    currencySign: currencyData ? currencyData.sign : "",
                    currencyName: currencyData ? currencyData.name : "",
                    currencyFlag: currencyData ? currencyData.flag : "",
                };
            });

            $exchangeRates = [];
            for (let ex of res) {
                $exchangeRates.push({ ...ex });
            }

            if ($exchangeRates.length == 0) {
                console.log("loadExchangeRates().empty");
                $appInitFailed = true;
                $appInitFailedDetails.push($_("no_data", { values: { type: $_("exchange_rates") } }));
            } else {
                $exchangeRates = $exchangeRates;
                console.log("Loaded ExchangeRates: ", { $exchangeRates });
            }
        } catch (error) {
            console.log("loadExchangeRates().error");
            console.log(error);
            if (!handleApiError($_("unexpected_error_in", { values: { step: "loadExchangeRates()" } }), error, 'fatal')) throw(error);
        }
    };

    const loadProductHierarchy = async () => {
        try {
            const res = await getProductHierarchy();

            console.log({ res });

            $productHierarchyList = [];
            for (let row of res) {
                // console.log({row});
                $productHierarchyList.push({ ...row });
            }

            if ($productHierarchyList.length == 0) {
                console.log("loaloadProductHierarchydDCs().empty");
                $appInitFailed = true;
                $appInitFailedDetails.push(
                    $_("no_data", {
                        values: { type: $_("loadProductHierarchy") },
                    }),
                );
            } else {
                // $divisionsList = [...divsArray];
                $productHierarchyList = $productHierarchyList;
                console.log({ $productHierarchyList });
            }
        } catch (error) {
            console.log("loadProductHierarchy().error");
            console.log(error);
            if (!handleApiError($_("unexpected_error_in", { values: { step: "loadProductHierarchy()" } }), error, 'fatal')) throw(error);
        }
    };

    const loadBuyers = async () => {
        try {
            const res = await getUserManagement(null, "Buyer");

            console.log({ res });

            $buyersList = [];
            for (let row of res) {
                // console.log({row});
                $buyersList.push({ ...row });
            }

            if ($buyersList.length == 0) {
                console.log("loadBuyers().empty");
                $appInitFailed = true;
                $appInitFailedDetails.push(
                    $_("no_data", {
                        values: { type: $_("loadBuyers") },
                    }),
                );
            } else {
                // $divisionsList = [...divsArray];
                $buyersList = $buyersList;
                console.log({ $buyersList });
            }
        } catch (error) {
            console.log("loadBuyers().error");
            console.log(error);
            if (!handleApiError($_("unexpected_error_in", { values: { step: "loadBuyers()" } }), error, 'fatal')) throw(error);
        }
    };

    const loadDashboard = async () => {
        try {
            const res = await getWeekDashboard();

            console.log({ res });

            if (res && Array.isArray(res))
            res.forEach(w => {
                if (w.forecasts && Array.isArray(w.forecasts)) {
                    w.forecasts.forEach(r => {
                        r.fcPurchasedLines = r.fcPurchasedLines + r.fcPurchasedLessLines + r.fcPurchasedMoreLines;
                    });
                }
            });

            $dashboardList = [];
            for (let row of res) {
                // console.log({row});
                $dashboardList.push({ ...row });
            }

            if ($dashboardList.length == 0) {
                console.log("loadDashboard().empty");
                $appInitFailed = true;
                $appInitFailedDetails.push($_("no_data", { values: { type: $_("loadDashboard") } }));
            } else {
                // $divisionsList = [...divsArray];
                $dashboardList = $dashboardList;
                console.log({ $dashboardList });
            }
        } catch (error) {
            console.log("loadDashboard().error");
            console.log(error);
            if (!handleApiError($_("unexpected_error_in", { values: { step: "loadDashboard()" } }), error, 'fatal')) throw(error);
        }
    };

    const loadAppParameters = async () => {
        try {
            const res = await getAppParameters();

            console.log({ res });

            $appParameters = {};
            for (let row of res) {
                console.log({row});
                $appParameters[row.name] = {...row};
            }

            if (Object.keys($appParameters).length == 0) {
                console.log("loadAppParameters().empty");
                $appInitFailed = true;
                $appInitFailedDetails.push($_("no_data", { values: { type: $_("loadAppParameters") } }));
            } else {
                // $appParameters = $appParameters;
                console.log({ $appParameters });
            }
        } catch (error) {
            console.log("loadAppParameters().error");
            console.log(error);
            if (!handleApiError($_("unexpected_error_in", { values: { step: "loadAppParameters()" } }), error, 'fatal')) throw(error);
        }
    };


    function initTokenRefreshTimer(init) {
        console.log("==== initTokenRefreshTimer() ====");
        console.log($authenticatedUser.tokenExpirationDate);

        function addMinutes(date, minutes) {
            return new Date(date.getTime() + minutes * 60000);
        }

        // Call token refresh after 50 minutes
        let toAdd = 50;

        // Check Token expiration date - only on initial login
        if (init) {
            let now = new Date();
            let refreshDate = addMinutes(now, toAdd);

            if (refreshDate > $authenticatedUser.tokenExpirationDate) {
                // Access token will expire sonner
                let dateDiffMinutes = Math.trunc(($authenticatedUser.tokenExpirationDate - now) / 60000);
                if (dateDiffMinutes < 10) {
                    toAdd = 0;
                } else {
                    toAdd = dateDiffMinutes - 10;
                }
            }
        }

        console.log(`initTokenRefreshTimer(): scheduling token refresh to now + ${toAdd} minutes`);
        setTimeout(
            async () => {
                let loggedOut = getValue(userLoggedOut);
                console.log("loggedOut: ", loggedOut);
                if (!loggedOut) {
                    console.log("Refreshing…");
                    try {
                        await userTokenRefresh();
                    } catch (e) {
                        event.preventDefault();
                        console.log("userTokenRefresh call failed", { e });
                    }
                    console.log("…done");
                    initTokenRefreshTimer();
                } else {
                    console.log("User logged out. Token refresh cancelled.");
                }
            },
            toAdd * 60 * 1000,
        );
    }
    /*
    async function refreshWeekStatus(weekId) {
        console.log("========= refreshWeekStatus =============", { weekId });
        let loggedOut = getValue(userLoggedOut);

        console.log("loggedOut: ", loggedOut);
        if (!loggedOut) {
            console.log("Refreshing week status…");
            try {
                const ret = await apiGetWeek(weekId);
                if (ret && ret.length > 0) {
                    console.log({ ret });
                    return ret.at(0);
                }
            } catch (e) {
                console.log("getWeek call failed", { e });
            }
        } else {
            console.log("User logged out. Week status refresh cancelled.");
            throw new Error("Logged out");
        }
    }

     function initWeekStatusRefreshTimer() {
        console.log("==== initWeekStatusRefreshTimer() ====");

        setTimeout(async () => {
            let cWeek = getValue(selectedWeek);
            let weekId = cWeek.id;
            console.log({ weekId }, { $weeks });
            try {
                const ret = await refreshWeekStatus(weekId);
                console.log({ ret });

                let updated = false;
                $weeks.forEach((w) => {
                    if (w.id == ret.id && w.status != ret.status) {
                        w.status = ret.status;
                        updated = true;
                    }
                });

                if (updated) {
                    console.log("Week status changed!", ret.status);
                    $selectedWeek = $weeks.filter((x) => x.id == weekId).at(0);
                    $weeks = $weeks;
                }
                console.log("…done");
                initWeekStatusRefreshTimer();
            } catch (e) {
                console.log("Error: ", { e });
            }
        }, 600000);
    } */
</script>

<TescoAppLogo />
{#if loading || $isLoading}
    <Loader size="big" text={$_("logging_in_wait_please")} />
{:else}
    <CustomCard title={$_("bt_produce_login")} size="medium">
        <Paper class="">
            <Content>
                <Flex direction="column" class="gap-1 w-100">
                    {#if $sessionExpired}
                        <div class="tescored-text">
                            {$_("session_expired_relogin")}
                        </div>
                    {:else if $userLoggedOut}
                        <div class="tescoblue-text">
                            {$_("you_have_logged_out")}
                        </div>
                    {:else}
                        {#if $loginAttemptCount > maxLoginAttempts}
                            <div class="tescored-text">
                                {$_("max_login_attempts_reached", {
                                    values: { maxAttempts: maxLoginAttempts },
                                })}
                            </div>
                        {/if}
                        {#if loginMsg}
                            <div class="tescored-text">{loginMsg}</div>
                        {/if}
                    {/if}
                </Flex>
            </Content>
        </Paper>
        <Button
            variant="raised"
            class=""
            on:click={() => {
                $loginAttemptCount = 0;
                $userLoggedOut = false;
                login();
            }}
            title={$_("relogin")}
        >
            <Icon tag="svg" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiLogin} />
            </Icon>
            {$_("relogin")}
        </Button>
    </CustomCard>
{/if}

<style>
</style>
