<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { _ } from "svelte-i18n";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Checkbox from "@smui/checkbox";
    import DTSelectCell from "../common/DTSelectCell.svelte";

    export let supplierList = [];
    export let showSupplierList = false;
    export let supplierId;
    export let showFillProductListCheckbox = false;
    export let fillProductList;

</script>

<section class="scroll-on">
    <DataTable class="w-100 input-form no-border pa-2">
        <Body>

            {#if showSupplierList}
            <Row>
                <Cell class="w-30 form-label">{$_("select_supplier")}</Cell>
                <DTSelectCell class="w-100" 
                    bind:value={supplierId} 
                    list={supplierList} 
                    format={{ showId: false, translateName: false }}
                />
            </Row>               
            {/if}

            {#if showFillProductListCheckbox}
            <Row>
                <Cell class="w-30 form-label">{$_("fill_product_list")}</Cell>
                <Cell class="w-100">
                    <Checkbox 
                        bind:checked={fillProductList} 
                    /> 
                </Cell>
            </Row>                
            {/if}

        </Body>
    </DataTable>
</section>
        

<style>
    section {
        padding: 0.5rem;
    }
</style>
